import React, { Fragment } from "react"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import Drawer from "@material-ui/core/Drawer"
import Toolbar from "@material-ui/core/Toolbar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { Link } from "gatsby"
import useIsMobile from "../utils/useIsMobile"
import AppBar, { HideOnScroll } from "./AppBar"
import dashboardConfig from "../constants/dashboardConfig"
import { Divider } from "@material-ui/core"

// eslint-disable-next-line react/display-name
const configPropsToListItem = ({ selectedIndex, handleDrawerClose }) => (
  { path, title, Icon, divider },
  index
) =>
  divider ? (
    <Divider variant="middle" key={index} />
  ) : (
    <ListItem
      button
      key={index}
      selected={selectedIndex === -1 ? index === 0 : selectedIndex === index}
      component={Link}
      to={`/dashboard/${path}`}
      onClick={handleDrawerClose}
      style={{ width: "100%" }}
    >
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={title} />
    </ListItem>
  )

const drawerWidth = 240

const useStyles = isMobile =>
  makeStyles(theme => ({
    wrapper: {
      display: "flex",
      flexGrow: 1,
      minHeight: "100vh",
      // overflow: "hidden",
      flexDirection: "column",
      // border: "1px solid blue",
    },
    root: {
      display: "flex",
      flexGrow: 1,
      overflow: "hidden",
      flexDirection: "row",
      margin: 0,
      padding: 0,
      // border: "1px solid red",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      position: "relative",
      top: 0,
      // display: "flex",
      // flex: 1,
      // flexDirection: 'column',
      width: isMobile ? "100%" : drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerPaper: {
      width: isMobile ? "100%" : drawerWidth,
    },
    drawerOpen: {
      width: isMobile ? "100%" : drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: 0,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(7) + 1,
      },
    },
    drawerContainer: {
      display: "flex",
      flexGrow: 1,
      overflow: "hidden",
      flexDirection: "column",
      margin: 0,
      padding: 0,
      // border: "1px solid red",
    },
    dashboardContent: {
      display: "flex",
      flexGrow: 1,
      overflow: "hidden",
      flexDirection: "column",
      margin: 0,
      padding: 0,
      // border: "1px solid red",
    },
    toolbar: {
      display: "flex",
    },
  }))
export default function DrawerWrapper({
  children,
  groups = [],
  showDrawer = false,
  location,
}) {
  const isMobile = useIsMobile()
  const classes = useStyles(isMobile)()
  const [open, setOpen] = React.useState(!isMobile)
  React.useEffect(() => {
    setOpen(!isMobile)
  }, [isMobile])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    if (isMobile) setOpen(false)
  }

  const group = groups.includes("ShopsAdmin") ? "ShopsAdmin" : "ShopEmployee"
  const selectedIndex = dashboardConfig[group].findIndex(
    ({ path }) =>
      location?.pathname === `/dashboard/${path}` ||
      location?.pathname === `/dashboard/${path}/`
  )
  console.log({
    selectedIndex,
    group,
    showDrawer,
    location,
    isMobile,
  })
  return (
    <div className={classes.wrapper}>
      <div
        className={classes.root}
        //   style={{ flexDirection: idMatch ? "column" : "row" }}
      >
        <AppBar
          location={location}
          showDrawer={handleDrawerOpen}
          hideDrawer={handleDrawerClose}
          drawerOpen={open}
        />
        {showDrawer && (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open || !isMobile,
              [classes.drawerClose]: isMobile && !open,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open || !isMobile,
                [classes.drawerClose]: isMobile && !open,
              }),
            }}
          >
            <HideOnScroll active>
              <Toolbar />
            </HideOnScroll>
            <div className={classes.drawerContainer}>
              <List style={{ padding: 0, margin: 0, width: "100%" }}>
                {dashboardConfig[group].map(
                  configPropsToListItem({ selectedIndex, handleDrawerClose })
                )}
              </List>
            </div>
          </Drawer>
        )}
        <main className={classes.dashboardContent}>
          <HideOnScroll active>
            <Toolbar className={classes.toolbar} />
          </HideOnScroll>
          {React.cloneElement(children, { component: Fragment })}
        </main>
      </div>
    </div>
  )
}
