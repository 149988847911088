import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Typography from "@material-ui/core/Typography"
import Box from "@material-ui/core/Box"
import BillingPanelContent from "./BillingPanelContent"
import PrimaryButton from "./PrimaryButton"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box pt={2}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
    // maxWidth: 720,
    // width: "100%",
    margin: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  marginLeft: {
    marginLeft: 0,
  },
  marginHorizontal: {
    left: 0,
    right: 0,
    // marginLeft: theme.spacing(2),
    // marginRight: theme.spacing(2),
    // display: "flex",
  },
}))

export default function BillingTabs({
  payedSeats,
  planCanSell,
  planCanVisio,
  payedVideoSeats,
  stripeOk,
  onStripePress,
  onInfoClick,
  onPaymentMethodUpdateClick,
}) {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <div className={classes.root}>
      {/* <AppBar position="static" className={classes.marginHorizontal}> */}
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
        variant="fullWidth"
      >
        <Tab label="Mon plan" {...a11yProps(1)} />
        <Tab label="Paiements clients" {...a11yProps(0)} />
      </Tabs>
      {/* </AppBar> */}
      <TabPanel value={value} index={1} style={{ padding: 0 }}>
        <BillingPanelContent
          title="Gestion des paiements clients"
          subtitle="HERVE.SHOP vous permet d'encaisser les règlements de vos clients grâce à Stripe®."
          buttonText="Consulter / modifier mes informations"
          feedbacks={[
            {
              [planCanSell ? "success" : "warning"]: `Votre plan actuel ${
                planCanSell ? "permet" : "ne permet pas"
              } à vos clients de payer en ligne`,
            },
            stripeOk
              ? {
                  success:
                    "Vous êtes prêts à recevoir les paiements de vos clients !",
                }
              : {
                  warning:
                    "La configuration de votre compte Stripe® n'est pas terminée.",
                },
          ]}
          onButtonPress={onStripePress}
        />
      </TabPanel>
      <TabPanel value={value} index={0}>
        <BillingPanelContent
          title="Mon plan"
          subtitle={
            <Typography className={classes.marginBottom}>
              {"Votre plan actuel vous permet de connecter les calendriers de "}
              <Typography
                component="span"
                display="inline"
                style={{ fontWeight: "bold" }}
              >{`${payedSeats} conseiller(s)`}</Typography>
            </Typography>
          }
          feedbacks={[
            {
              [planCanSell ? "success" : "warning"]: `Votre plan actuel ${
                planCanSell ? "permet" : "ne permet pas"
              } à vos clients de payer en ligne`,
            },
            {
              [planCanVisio ? "success" : "warning"]: `Votre plan actuel ${
                planCanVisio ? "permet" : "ne permet pas"
              } à vos clients de prendre des rendez-vous en vidéo avec ${
                planCanVisio ? `${payedVideoSeats} de` : ""
              } vos conseillers`,
            },
          ]}
          buttonText="Mettre à jour mon moyen de paiement"
          onButtonPress={onPaymentMethodUpdateClick}
        >
          <Box mt={2}>
            <PrimaryButton inverted onClick={onInfoClick}>
              Consulter / modifier mon plan
            </PrimaryButton>
          </Box>
        </BillingPanelContent>
      </TabPanel>
    </div>
  )
}
