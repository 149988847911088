import React from "react"
import TextField from "@material-ui/core/TextField"
// import timekit from "timekit-sdk"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import LoadingBackdrop from "./LoadingBackDrop"
import SimpleAccordion from "./SimpleAccordion"
import { Avatar, Typography } from "@material-ui/core"
import Constraint from "./Constraint"
import { selectResource, updateUserResource } from "../app/user"
import { useSelector, useDispatch } from "react-redux"
import BackButton from "./BackButton"
import { restAuth } from "../services/rest"

const cloneDeep = require("clone-deep")
const merge = require("deepmerge")
var equal = require("deep-equal")

const DynamoValuesToFormValues = data => {
  if (!data) return {}
  const formValues = cloneDeep(data)
  Object.entries(formValues).forEach(([key, value]) => {
    try {
      formValues[key] = JSON.parse(value)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  })
  return formValues
}

const formValuesToApiValues = formValues => {
  const apiValues = cloneDeep(formValues)
  const deleteKeys = [
    "createdAt",
    "updatedAt",
    "created_at",
    "updated_at",
    "email_verified",
    "explicit_consent",
  ]
  deleteKeys.forEach(key => {
    delete apiValues[key]
  })
  delete apiValues.resources
  return apiValues
}

const TextInput = props => <TextField variant="outlined" fullWidth {...props} />

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  spacedChildren: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}))
export default function ResourceForm({ id }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [cloudValues, setCloudValues] = React.useState({})
  const [formValues, setFormValues] = React.useState({})
  const cloudResource = useSelector(selectResource(id))
  const [loading, setLoading] = React.useState(false)
  React.useEffect(() => {
    setFormValues(DynamoValuesToFormValues(cloudResource))
    setCloudValues(DynamoValuesToFormValues(cloudResource))
  }, [cloudResource])
  const updateResource = () => {
    const body = formValuesToApiValues(formValues)
    console.log({ body })
    setLoading(true)
    restAuth("post", "/auth/updateResource", body)
      .then(() => dispatch(updateUserResource(formValues)))
      .catch(err => console.warn("project update failed", err))
      .finally(() => setLoading(false))
  }
  const addAvailability = () =>
    setFormValues(values => {
      const newValues = cloneDeep(values)
      if (!newValues.availability_constraints)
        newValues.availability_constraints = []
      newValues.availability_constraints.push({
        allow_day_and_time: {
          day: "Monday",
          start: "10:00",
          end: "18:00",
        },
      })
      return newValues
    })
  const {
    first_name = "",
    last_name = "",
    email = "",
    image = "",
    availability_constraints = [],
  } = formValues
  console.log({
    cloudValues,
    formValues,
    equal: equal(formValues, cloudValues),
  })
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <div style={{ alignSelf: "flex-start" }}>
        <BackButton />
      </div>
      <Button
        variant="contained"
        disabled={equal(formValues, cloudValues)}
        style={{
          position: "sticky",
          alignSelf: "flex-start",
          marginBottom: 20,
          margin: 10,
        }}
        onClick={updateResource}
      >
        Appliquer les modifications
      </Button>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflowY: "scroll",
          overflowX: "hidden",
          padding: 10,
        }}
      >
        <TextInput value={id} id="id" style={{ display: "none" }} />
        <SimpleAccordion>
          <Typography> Informations générales </Typography>
          <div
            className={classes.spacedChildren}
            style={{
              display: "flex",
              width: "100%",
              paddingTop: 10,
              flexDirection: "column",
            }}
          >
            <TextInput
              value={first_name}
              id="first_name"
              label="Nom"
              onChange={event => {
                const first_name = event.target.value
                setFormValues(values => merge(values, { first_name }))
              }}
            />
            <TextInput
              value={last_name}
              id="last_name"
              label="Prénom"
              onChange={event => {
                const last_name = event.target.value
                setFormValues(values => merge(values, { last_name }))
              }}
            />
            <TextInput
              value={formValues?.email?.endsWith("@timekit.io") ? "" : email}
              disabled={!cloudValues?.email?.endsWith("@timekit.io")}
              id="email"
              label="E-mail"
              onChange={event => {
                const email = event.target.value || cloudValues.email
                setFormValues(values => merge(values, { email }))
              }}
            />
            <TextField
              fullWidth
              variant="outlined"
              label="Illustration (url)"
              value={image || ""}
              onChange={event => {
                const image = event.target.value
                setFormValues(values => merge(values, { image }))
              }}
            />
            {(image || true) && (
              <Avatar
                src={image || "https://source.unsplash.com/featured/?shopping"}
                style={{ height: 100, width: 100 }}
              />
            )}
          </div>
        </SimpleAccordion>
        <SimpleAccordion>
          <Typography> Créneaux ouverts aux réservations </Typography>
          <div
            className={classes.spacedChildren}
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
            }}
          >
            {(availability_constraints || []).map((constraint, index) =>
              Object.keys(constraint)[0] === "allow_day_and_time" ? (
                <Constraint
                  key={index}
                  data={constraint}
                  index={index}
                  setFormValues={setFormValues}
                />
              ) : null
            )}
            {(availability_constraints || []).map((constraint, index) =>
              Object.keys(constraint)[0] === "block_period" ? (
                <Constraint
                  key={index}
                  data={constraint}
                  index={index}
                  setFormValues={setFormValues}
                />
              ) : null
            )}
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Button variant="contained" onClick={addAvailability}>
                Ajouter un créneau de disponibilité
              </Button>
              {/* <div style={{ width: 10 }} />
              <Button
                variant="contained"
                onClick={() =>
                  setFormValues((values) => {
                    const newValues = cloneDeep(values);
                    if (!newValues.availability_constraints)
                      newValues.availability_constraints = [];
                    newValues.availability_constraints.push({
                      block_period: {
                        start: new Date()
                          .toISOString()
                          .split("T")
                          .join(" ")
                          .split(".")[0],
                        end: addDays(new Date(), 1)
                          .toISOString()
                          .split("T")
                          .join(" ")
                          .split(".")[0],
                      },
                    });
                    return newValues;
                  })
                }
              >
                Ajouter une période d'indisponibilité
              </Button> */}
            </div>
          </div>
        </SimpleAccordion>
      </div>
      {/* <LoadingBackdrop open={loading || !formValues.id} /> */}
      <LoadingBackdrop open={loading} />
    </div>
  )
}
