import React from "react"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { withStyles } from "@material-ui/core"

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    marginTop: 10,
    elevation: 10,
    // boxShadow: "none",
    "&:not(:last-child)": {
      // borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      // margin: 0,
      marginTop: 10,
    },
  },
  expanded: {},
})(MuiAccordion)

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .1)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    "&$expanded": {
      //   minHeight: 56,
      //   color: "secondary",
    },
  },
  content: {
    "&$expanded": {},
  },
  expanded: {
    // marginTop: 0,
    // color: "secondary",
  },
})(MuiAccordionSummary)

export default function SimpleAccordion({ children }) {
  return (
    <Accordion>
      {React.Children.map(children, (child, i) => {
        return i === 0 ? (
          <AccordionSummary
            // style={{ marginTop: 0 }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {child}
          </AccordionSummary>
        ) : (
          <AccordionDetails style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}>
            {child}
          </AccordionDetails>
        )
      })}
    </Accordion>
  )
}
