import React from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const defaultMessages = {
  error: "Une erreur a été rencontrée",
  warning: "Attention",
  info: "Information",
  success: "L'opération s'est déroulée avec succès",
}

export default function SimpleSnack({
  open,
  handleClose,
  severity = "success",
  message = defaultMessages[severity],
  autoHideDuration = 5000,
}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  )
}
