import "date-fns"
import React from "react"
import DateFnsUtils from "@date-io/date-fns"
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"

export default function TimePicker({ label, value, onChange, disabled }) {
  // The first commit of Material-UI
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardTimePicker
        disabled={disabled}
        margin="dense"
        ampm={false}
        minutesStep={5}
        // id="time-picker"
        label={label}
        value={value}
        cancelLabel="Annuler"
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
        style={{ minWidth: 80, margin: 0, padding: 0 }}
      />
    </MuiPickersUtilsProvider>
  )
}
