import React, { Fragment } from "react"
import { Router } from "@reach/router"
import { useSelector } from "react-redux"
import Amplify, { I18n } from "aws-amplify"
import awsconfig from "../utils/updateAmplifyConfig"
import EmployeeAccount from "../components/EmployeeAccount"
import amplifyAuthFr from "../utils/amplifyAuthFr"
import DrawerWrapper from "../components/DrawerWrapper"
import ProjectForm from "../components/ProjectForm"
import ResourceForm from "../components/ResourceForm"
import UserResources from "../components/UserResources"
import EmployeeDashboard from "../components/EmployeeDashboard"
import { selectCognitoUser } from "../app/cognitoUser"
import EmployeeCalendar from "../components/EmployeeCalendar"
import { selectUser } from "../app/user"
import AdminDashboard from "../components/AdminDashboard"
import Attributions from "../components/Attributions"
import AuthCallback from "../components/AuthCallback"
import AdminBooking from "../components/AdminBooking"
import BookingList from "../components/BookingList"
import Billing from "../components/Billing"
import "../components/layout.css"
import { makeStyles } from "@material-ui/core/styles"
import AppBar from "../components/AppBar"
import { Box, Paper, Toolbar, Typography } from "@material-ui/core"
import Communication from "../components/Communication"
import Legal from "../components/Legal"
import "../CSS/dashboard.css"
import GuestPage from "../components/GuestPage"
import GoogleSignIn from "../components/GoogleSignIn"
import Alert from "@material-ui/lab/Alert"
import { ChatWidget } from "@papercups-io/chat-widget"

const useStyles = makeStyles(theme => ({
  wrapper: {
    // position: "absolute",
    // height: "100vh",
    // width: "100%",
    // display: "flex",
    // overflow: "auto",
    // flexDirection: "column",
  },
  root: {
    display: "flex",
    flexGrow: 1,
    minHeight: "100vh",
    background: "white",
    flexDirection: "column",
    // border: "1px solid blue",
  },
  button: {
    alignSelf: "center",
    background: "white",
    textTransform: "none",
    fontFamily: "Roboto",
  },
  signInPaper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(2),
    margin: theme.spacing(1),
  },
}))

I18n.putVocabulariesForLanguage("fr-FR", amplifyAuthFr)

Amplify.configure(awsconfig)

function App({ location }) {
  const classes = useStyles()
  const cognitoUser = useSelector(selectCognitoUser)
  const user = useSelector(selectUser)
  const isShopAdmin = cognitoUser?.groups?.includes("ShopsAdmin")
  const isShopEmployee = cognitoUser?.groups?.includes("ShopEmployee")
  const showAdmin = isShopAdmin
  const showEmployee = !isShopAdmin && isShopEmployee
  const showGuest = !isShopAdmin && !isShopEmployee
  React.useEffect(() => {
    if (cognitoUser) {
      if (!cognitoUser?.groups?.includes[("ShopsAdmin", "ShopEmployee")]) {
        // window.location.href = "https://herve.shop"
      }
    }
  }, [cognitoUser])
  console.log("dashboard", { cognitoUser, user, location })
  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <ChatWidget
            accountId="dc866fdc-345c-485f-bdb1-e34025a83815"
            title="Bienvenue sur HERVE.SHOP !"
            subtitle="Comment pouvons nous vous aider ? 😊"
            primaryColor="#ff6363"
            greeting="Bonjour ! Je suis à votre écoute"
            awayMessage="Bonjour ! nous ne sommes pas disponibles pour le moment, laissez un message svp !"
            newMessagePlaceholder="Ecrire quelque chose ici ..."
            showAgentAvailability={true}
            agentAvailableText="Nous sommes en ligne !"
            agentUnavailableText="Nous sommes absents pour le moment."
            requireEmailUpfront={true}
            iconVariant="filled"
            emailInputPlaceholder="Renseignez votre email"
            newMessagesNotificationText="Voir les nouveaux messages"
            baseUrl="https://herve-shop-chat.herokuapp.com"
            // Optionally include data about your customer here to identify them
            customer={
              cognitoUser
                ? {
                    name: `${cognitoUser.given_name} ${cognitoUser.family_name}`,
                    email: cognitoUser.email,
                    external_id: cognitoUser.username,
                    metadata: {
                      groups: cognitoUser.groups.join(","),
                    },
                  }
                : {}
            }
          />
          {/* <AppBar location={location} /> */}
          {/* <AmplifyAuthenticator path="dashboard"> */}
          {/* <SignIn slot="sign-in" /> */}
          {!cognitoUser ? (
            <Box className={classes.root}>
              <AppBar />
              <Toolbar />
              <Box
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Paper elevation={5} className={classes.signInPaper}>
                  <Typography
                    align="center"
                    variant="h5"
                    paragraph
                    style={{ width: "100%" }}
                  >
                    Connexion
                  </Typography>
                  <GoogleSignIn />
                  <Box
                    mt={2}
                    alignSelf="flex-start"
                    style={{ minWidth: 0, overflow: "hidden" }}
                  >
                    <Alert severity="info" style={{ maxWidth: 300 }}>
                      HERVE.SHOP est bientôt compatible avec votre compte
                      Microsoft®
                    </Alert>
                  </Box>
                </Paper>
              </Box>
            </Box>
          ) : (
            <Router primary={false} component={Fragment}>
              {showAdmin && (
                <DrawerWrapper
                  path="/"
                  groups={cognitoUser?.groups}
                  showDrawer={
                    !location.pathname.startsWith("/dashboard/booking/")
                  }
                  location={location}
                >
                  <AdminDashboard default />
                  {user?.projects?.items[0].id && (
                    <ProjectForm
                      id={user.projects.items[0].id}
                      path="dashboard/project"
                    />
                  )}
                  <UserResources
                    path="dashboard/resources"
                    context="adminResources"
                  />
                  <ProjectForm path="dashboard/project/:id" />
                  <EmployeeAccount
                    path="dashboard/account"
                    id={cognitoUser.username}
                  />
                  <ResourceForm path="dashboard/resource/:id" />
                  <AuthCallback path="dashboard/authcb" />
                  <Attributions path="dashboard/attributions" />
                  <BookingList path="dashboard/bookings" />
                  <AdminBooking path="dashboard/booking/:id" />
                  <Billing path="dashboard/billing" />
                  <EmployeeCalendar
                    path="dashboard/calendar"
                    id={cognitoUser.username}
                  />
                  <EmployeeCalendar
                    path="dashboard/calendar/:id"
                    owned={false}
                  />
                  <Communication path="dashboard/communication" />
                  <Legal path="dashboard/legal" />
                </DrawerWrapper>
              )}
              {showEmployee && (
                <DrawerWrapper
                  path="/"
                  groups={cognitoUser?.groups}
                  showDrawer={
                    !location.pathname.startsWith("/dashboard/booking/")
                  }
                  location={location}
                >
                  <EmployeeDashboard default id={cognitoUser.username} />
                  <EmployeeCalendar
                    path="dashboard/calendar"
                    id={cognitoUser.username}
                  />
                  <EmployeeAccount
                    path="dashboard/account"
                    id={cognitoUser.username}
                  />
                  <AuthCallback path="dashboard/authcb" />
                  <AdminBooking path="dashboard/booking/:id" />
                  <BookingList path="dashboard/bookings" />
                </DrawerWrapper>
              )}
              {showGuest && (
                <DrawerWrapper
                  path="/"
                  groups={cognitoUser?.groups}
                  showDrawer={false}
                  location={location}
                >
                  <GuestPage default />
                  <EmployeeAccount
                    path="dashboard/account"
                    id={cognitoUser.username}
                  />
                </DrawerWrapper>
              )}
            </Router>
          )}
          {/* </AmplifyAuthenticator> */}
        </div>
      </div>
    </div>
  )
}

export default App
