import React from "react"
import SecondaryButton from "./SecondaryButton"

export default function FileInputButton({ 
  title, 
  accept, 
  onChange }) {
  
  return (
    <SecondaryButton component="label">
      {title}
      <input
        type="file"
        accept={accept}
        hidden
         onChange={event => {
          event.persist()
          const {
            target: { value, files },
          } = event
          const fileForUpload = files[0]
          onChange(fileForUpload || value)
       }}
      />
    </SecondaryButton>
  )
}
