import React from "react"
import { useForm } from "react-hook-form"

export default function Form({ defaultValues, children, onSubmit }) {
  const methods = useForm({ defaultValues })
  const { handleSubmit, reset } = methods
  const { dirtyFields, isDirty, isSubmitting } = methods.formState

  return (
    <form onSubmit={handleSubmit(onSubmit({ dirtyFields, reset }))}>
      {React.Children.map(children, child => {
        if (child.props.type === "submit") {
          return React.createElement(child.type, {
            ...{
              ...child.props,
              disabled: !isDirty || isSubmitting,
            },
          })
        }
        return child.props.name
          ? React.createElement(child.type, {
              ...{
                ...child.props,
                register: methods.register,
                watch: methods.watch,
                control: methods.control,
                setValue: methods.setValue,
                key: child.props.name,
              },
            })
          : child
      })}
    </form>
  )
}
