import {
  Box,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectUser } from "../app/user"
import { API, graphqlOperation } from "aws-amplify"
import UserAvailablityForm from "./UserAvailablityForm"
import DateRangeIcon from "@material-ui/icons/DateRange"
import { navigate } from "gatsby"
import { restAuth } from "../services/rest"
import { fetchUserData } from "./AuthListener"
import { selectCognitoUser } from "../app/cognitoUser"
import DashboardPage from "./DashboardPage"
import useIsMobile from "../utils/useIsMobile"
import HervePaper from "./HervePaper"
import SimpleSnack from "./SimpleSnack"
import LoadingBackdrop from "./LoadingBackDrop"
import GoogleButton from "./GoogleButton"
var equal = require("deep-equal")

const UPDATE_USER = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      selectedCalendars
    }
  }
`

const useFormStyles = makeStyles(() => ({
  label: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}))

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: 0,
    background: theme.palette.background.main,
    // color: theme.palette.primary.main,
  },
  title: {
    margin: theme.spacing(2),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(2),
    alignItems: "center",
  },
  paper: {
    display: "flex",
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    flexDirection: "column",
  },
  paperTitle: {
    marginBottom: theme.spacing(2),
  },
  scroll: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "auto",
  },
}))
export default function EmployeeCalendar({ owned = true, id }) {
  const classes = useStyles()
  const formClasses = useFormStyles()
  const isMobile = useIsMobile()
  const location = useLocation()
  const { refresh } = queryString.parse(location.search)
  const cloudUser = useSelector(selectUser)
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(false)
  const [fetching, setFetching] = React.useState(false)
  const [error, setError] = React.useState(false)
  const cognitoUser = useSelector(selectCognitoUser)
  const [calendars, setCalendars] = React.useState({})
  const [cloudValues, setCloudValues] = React.useState([])
  const [formValues, setFormValues] = React.useState([])
  React.useEffect(() => {
    fetchUserData(cognitoUser, dispatch)
  }, [cognitoUser])
  React.useEffect(() => {
    if (!owned) return
    if (cloudUser?.calendarAccess) {
      setLoading(true)
      restAuth("get", "/auth/getCalendars")
        .then(response => {
          console.log({ response })
          if (response.success) {
            setCalendars({
              loading: false,
              error: false,
              data: response.calendars,
            })
          }
        })
        .catch(err => console.warn(err))
        .finally(() => setLoading(false))
    }
  }, [cloudUser?.calendarAccess])
  React.useEffect(() => {
    setCloudValues(cloudUser?.selectedCalendars || [])
    setFormValues(cloudUser?.selectedCalendars || [])
  }, [cloudUser])
  const handleChange = id => event => {
    if (event.target.checked) {
      setFormValues(values => [...values, id])
      return
    }
    setFormValues(values => values.filter(item => item !== id))
  }
  React.useEffect(() => {
    if (!cloudUser || equal(formValues, cloudValues)) {
      return
    }
    const input = { id: cloudUser.id, selectedCalendars: formValues }
    console.log({ input, formValues, cloudValues })
    API.graphql(graphqlOperation(UPDATE_USER, { input }))
      .then(() => fetchUserData(cognitoUser, dispatch))
      .catch(err => console.warn(err))
  }, [formValues])
  let availability
  if (owned) {
    availability = cloudUser?.availability
      ? JSON.parse(cloudUser.availability)
      : undefined
  } else {
    let worker = cloudUser?.ownedUsers.items.find(item => item.id === id)
    availability = worker?.availability
      ? JSON.parse(worker.availability)
      : undefined
  }
  console.log({ calendars, formValues, cloudValues, cloudUser, owned, refresh })
  return (
    <DashboardPage
      title={`Calendrier${
        owned
          ? ""
          : ` de ${
              cloudUser?.ownedUsers.items.find(item => item.id === id)
                .given_name
            } ${
              cloudUser?.ownedUsers.items.find(item => item.id === id)
                .family_name
            }`
      }`}
    >
      {owned ? (
        <HervePaper>
          {cloudUser?.calendarAccess ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <DateRangeIcon
                    style={{ marginLeft: -3, marginRight: 5 }}
                    fontSize="large"
                  />
                  Votre calendrier est connecté !
                </Typography>
              </div>
              <Typography
                variant="body1"
                style={{ marginTop: 10, marginBottom: 10 }}
              >{`${
                formValues?.length || 0
              } calendrier(s) synchronisé(s)`}</Typography>
              <Typography
                variant="body1"
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Sélectionnez les calendriers que vous souhaitez synchroniser
                avec HERVE.SHOP. Les évènements des calendriers sélectionnés
                seront pris en compte pour déterminer vos disponibilités.
              </Typography>
              {loading ? (
                <Box display="flex" flexDirection="row" alignItems="center">
                  <CircularProgress />
                  <Box ml={2}>
                    <Typography>
                      Chargement de la liste des calendriers
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  // maxHeight={200}
                  overflow="hidden"
                  display="flex"
                  alignSelf="stretch"
                  flexDirection="column"
                  alignItems="stretch"
                >
                  <Box
                    overflowX="auto"
                    flex={1}
                    alignSelf="stretch"
                    display="flex"
                    flexDirection="column"
                    alignItems="stretch"
                  >
                    <Grid container spacing={1}>
                      {calendars?.data?.map(item => (
                        <Grid
                          item
                          xs={12}
                          key={item.id}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <FormControlLabel
                            // classes={formClasses}
                            control={
                              <Checkbox
                                checked={formValues.includes(item.id)}
                                onChange={handleChange(item.id)}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={`${item.summary}${
                              item.primary ? " (principal)" : ""
                            }`}
                          />
                          {/* <Switch
                            checked={formValues.includes(item.id)}
                            color="primary"
                            onChange={handleChange(item.id)}
                            name={item.name}
                            inputProps={{ "aria-label": "secondary checkbox" }}
                          />
                          {item.description ? (
                            <Tooltip title={item.description}>
                              <Typography>{`${item.summary}${
                                item.primary ? " (principal)" : ""
                              }`}</Typography>
                            </Tooltip>
                          ) : (
                            <Typography>{`${item.summary}${
                              item.primary ? " (principal)" : ""
                            }`}</Typography>
                          )} */}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Box>
              )}
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography className={classes.paperTitle} variant="h5">
                Connectez votre calendrier Google
              </Typography>
              <Typography style={{ paddingBottom: 20 }}>
                Vous allez être redirigé vers une page vous permettant
                d'autoriser HERVE.SHOP à accéder à votre calendrier
              </Typography>
              <GoogleButton
                onClick={() => {
                  setFetching(true)
                  restAuth("get", "/auth/generateAuthUrl")
                    .then(response => {
                      if (response.error) {
                        throw Error(response.error)
                      }
                      setFetching(false)
                      navigate(response.data.url)
                    })
                    .catch(err => {
                      if (err?.message === "max limit reached") {
                        setError(true)
                      }
                      setFetching(false)
                    })
                }}
              >
                Se connecter avec Google
              </GoogleButton>
              <Box mt={2} />
              <Alert severity="info">
                Si vous venez de synchroniser votre calendrier, il peut être
                nécessaire de rafraîchir la page pour que la synchronisation
                soit prise en compte
              </Alert>
              {/* <Typography style={{ paddingTop: 20 }}>
                Si vous venez de synchroniser votre calendrier, il peut être
                nécessaire de rafraîchir la page pour que la synchronisation
                soit prise en compte
              </Typography> */}
            </div>
          )}
        </HervePaper>
      ) : null}
      <HervePaper
        title={owned ? "Renseignez vos disponibilités" : "Disponibilités"}
      >
        <UserAvailablityForm
          isMobile={isMobile}
          owned={owned}
          id={id || cloudUser?.id}
          availability={availability}
        />
      </HervePaper>
      <SimpleSnack
        handleClose={() => setError()}
        open={!!error}
        severity="warning"
        message="La limite de calendriers connectés est atteinte. Contactez le responsable de votre magasin"
      />
      <LoadingBackdrop open={!!fetching} />
    </DashboardPage>
  )
}
