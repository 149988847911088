import React from "react"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"
import { Button, MenuItem, Select } from "@material-ui/core"
import cloneDeep from "clone-deep"
import TimePicker from "./TimePicker"
import DatePicker from "./DatePicker"

export default function Constraint({ data, index, setFormValues }) {
  const handleDelete = () =>
    setFormValues(values => {
      const newValues = cloneDeep(values)
      newValues.availability_constraints = newValues.availability_constraints.filter(
        (_, i) => i !== index
      )
      return newValues
    })
  const key = Object.keys(data)[0]
  const { day, start, end } = data[key]
  const [startHours, startMinutes] =
    key === "allow_day_and_time"
      ? start.split(":")
      : start.split(" ")[1].split(":")
  const [endHours, endMinutes] =
    key === "allow_day_and_time" ? end.split(":") : end.split(" ")[1].split(":")
  const startValue = key === "allow_day_and_time" ? new Date() : new Date(start)
  startValue.setHours(parseInt(startHours, 10))
  startValue.setMinutes(parseInt(startMinutes, 10))
  const endValue = key === "allow_day_and_time" ? new Date() : new Date(end)
  endValue.setHours(parseInt(endHours, 10))
  endValue.setMinutes(parseInt(endMinutes, 10))
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        flex: 1,
        color: key === "allow_day_and_time" ? "green" : "red",
      }}
    >
      {key === "allow_day_and_time" ? <CheckCircleIcon /> : <CancelIcon />}
      <div style={{ width: 10 }} />
      {key === "allow_day_and_time" ? (
        <Select
          style={{ width: 100 }}
          variant="standard"
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={day || "Monday"}
          onChange={event =>
            setFormValues(values =>
              setFormValues(values => {
                const value = event.target.value
                const newValues = cloneDeep(values)
                newValues.availability_constraints[index][key].day = value
                return newValues
              })
            )
          }
        >
          <MenuItem value="Monday">Lundi</MenuItem>
          <MenuItem value="Tuesday">Mardi</MenuItem>
          <MenuItem value="Wednesday">Mercredi</MenuItem>
          <MenuItem value="Thursday">Jeudi</MenuItem>
          <MenuItem value="Friday">Vendredi</MenuItem>
          <MenuItem value="Saturday">Samedi</MenuItem>
          <MenuItem value="Sunday">Dimanche</MenuItem>
        </Select>
      ) : (
        <DatePicker
          label="Début"
          value={startValue}
          onChange={(date, value) =>
            setFormValues(values => {
              // const value = event.target.value;
              console.log({ value, date })
              const formatedValue = value.split("/").reverse().join("-")
              const newValues = cloneDeep(values)
              newValues.availability_constraints[index][key].start =
                key === "allow_day_and_time"
                  ? value
                  : [
                      formatedValue,
                      newValues.availability_constraints[index][
                        key
                      ].start.split(" ")[1],
                    ].join(" ")
              return newValues
            })
          }
        />
      )}
      <div style={{ width: 10 }} />
      <TimePicker
        label={key === "allow_day_and_time" ? "Ouverture" : ""}
        value={startValue}
        onChange={(date, value) =>
          setFormValues(values => {
            // const value = event.target.value;
            console.log({ value, date })
            const newValues = cloneDeep(values)
            newValues.availability_constraints[index][key].start =
              key === "allow_day_and_time"
                ? value
                : [
                    newValues.availability_constraints[index][key].start.split(
                      " "
                    )[0],
                    `${value}:00`,
                  ].join(" ")
            return newValues
          })
        }
      />
      <div style={{ width: 10 }} />
      {key !== "allow_day_and_time" && (
        <DatePicker
          label={"Fin"}
          value={endValue}
          onChange={(date, value) =>
            setFormValues(values => {
              // const value = event.target.value;
              console.log({ value, date })
              const formatedValue = value.split("/").reverse().join("-")
              const newValues = cloneDeep(values)
              newValues.availability_constraints[index][key].end =
                key === "allow_day_and_time"
                  ? value
                  : [
                      formatedValue,
                      newValues.availability_constraints[index][
                        key
                      ].start.split(" ")[1],
                    ].join(" ")
              return newValues
            })
          }
        />
      )}
      {key !== "allow_day_and_time" && <div style={{ width: 10 }} />}
      <TimePicker
        label={key === "allow_day_and_time" ? "Fermeture" : ""}
        value={endValue}
        onChange={(date, value) =>
          setFormValues(values => {
            // const value = event.target.value;
            console.log({ value, date })
            const newValues = cloneDeep(values)
            newValues.availability_constraints[index][key].end =
              key === "allow_day_and_time"
                ? value
                : [
                    newValues.availability_constraints[index][key].end.split(
                      " "
                    )[0],
                    `${value}:00`,
                  ].join(" ")
            return newValues
          })
        }
      />
      <div style={{ width: 10 }} />
      <Button variant="contained" onClick={handleDelete}>
        Supprimer
      </Button>
    </div>
  )
}
