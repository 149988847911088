import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { navigate } from "gatsby"
import { Avatar, makeStyles, Typography } from "@material-ui/core"
import CheckIcon from "@material-ui/icons/Check"
import WarningIcon from "@material-ui/icons/Warning"
import LoadingBackdrop from "./LoadingBackDrop"
import { restAuth } from "../services/rest"
import { fetchUserData } from "./AuthListener"
import { selectCognitoUser } from "../app/cognitoUser"

const errorMessages = {
  "confirmation time exceeded": `Désolé, le délai de confirmation a été dépassé.\n\nVous allez être redirigé vers la page de votre commerçant`,
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    background: theme.palette.primary.main,
    alignItems: "center",
    justifyContent: "space-around",
    padding: 20,
  },
  icon: { ...theme.typography.h1, color: "green" },
}))

export default function AuthCallback({ location }) {
  const classes = useStyles()
  const cognitoUser = useSelector(selectCognitoUser)
  const dispatch = useDispatch()
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState()
  const code = location.search.split("&")[0].slice(6)
  React.useEffect(() => {
    restAuth("get", `/auth/authurlcb?code=${code}`)
      .then(response => {
        console.log({ response })
        if (response.success) {
          fetchUserData(cognitoUser, dispatch)
          setTimeout(() => {
            navigate("/dashboard/calendar")
          }, 5000)
        } else {
          setError("La connection du calendrier a échoué")
        }
        setLoading(false)
      })
      .catch(err => {
        setError(err)
        setLoading(false)
        console.warn("failed to store token")
      })
  }, [code, dispatch])
  return (
    <div className={classes.root}>
      <LoadingBackdrop open={loading} />
      {!loading && (
        <div className={classes.root}>
          <Avatar
            style={{ background: "white", width: "20vw", height: "20vw" }}
          >
            {error ? (
              <WarningIcon className={classes.icon} style={{ color: "red" }} />
            ) : (
              <CheckIcon className={classes.icon} />
            )}
          </Avatar>
          <Typography
            variant="h4"
            style={{
              color: "white",
              textAlign: "center",
              whiteSpace: "pre-wrap",
            }}
          >
            {error
              ? errorMessages[error] || error.message || error
              : "Votre calendrier est bien connecté !"}
          </Typography>
          {!error && (
            <Typography
              variant="h6"
              style={{ color: "white", textAlign: "center" }}
            >
              Vous pouvez fermer cette page.
            </Typography>
          )}
        </div>
      )}
    </div>
  )
}
