import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import { Box, IconButton, Typography } from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import TimePicker from "./TimePicker"
// import cloneDeep from "clone-deep"
import { Controller, useFieldArray } from "react-hook-form"

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
})

const makeAvailabilityDate = str => {
  const [hours, minutes] = str.split(":")
  const d = new Date()
  d.setHours(parseInt(hours, 10))
  d.setMinutes(parseInt(minutes, 10))
  return d
}

const dayLocales = {
  fr: {
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche",
  },
}

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

// const getRows = availability => {
//   if (!availability)
//     return {
//       Monday: [[makeAvailabilityDate("08:00"), makeAvailabilityDate("10:00")]],
//       Tuesday: [],
//       Wednesday: [],
//       Thursday: [],
//       Friday: [],
//       Saturday: [],
//       Sunday: [],
//     }
// }

const WeekDay = ({ day, isMobile, control }) => {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: `availability.${day}`,
    }
  )
  return (
    <>
      {fields.map((item, index) => (
        <Controller
          key={item.id}
          render={({ field: { onChange, value } }) => (
            <Slot
              index={index}
              first={index === 0}
              day={day}
              slot={value}
              // setFormValues={setFormValues}
              isMobile={isMobile}
              control={control}
              onChange={onChange}
              remove={remove}
            />
          )}
          name={`availability.${day}.${index}`}
          control={control}
          defaultValue={item} // make sure to set up defaultValue
        />
      ))}
      {/* {daySlots.map((slot, index) => (
        <Slot
          key={index}
          index={index}
          first={index === 0}
          day={day}
          slot={slot}
          setFormValues={setFormValues}
          isMobile={isMobile}
          control={control}
        />
      ))} */}
      <Slot
        first={fields.length === 0}
        last
        day={day}
        // setFormValues={setFormValues}
        isMobile={isMobile}
        control={control}
        append={append}
      />
    </>
  )
}

const Slot = ({
  first,
  last,
  day,
  slot,
  index,
  // setFormValues,
  isMobile,
  onChange,
  append,
  remove,
}) => {
  const borderStyle = last ? { padding: 0 } : { border: "none", padding: 0 }
  const handleNewSlot = () => {
    append({
      0: makeAvailabilityDate("09:00"),
      1: makeAvailabilityDate("18:00"),
    })
    // setFormValues(values => {
    //   const newValues = cloneDeep(values)
    //   newValues.availability[day].push([
    //     makeAvailabilityDate("09:00"),
    //     makeAvailabilityDate("18:00"),
    //   ])
    //   return newValues
    // })
  }

  const handleRemoveSlot = () => {
    remove(index)
    // setFormValues(values => {
    //   const newValues = cloneDeep(values)
    //   newValues.availability[day].splice(index, 1)
    //   return newValues
    // })
  }

  const handleTimeChange = slotIndex => (date, value) => {
    if (!value || value.includes("_")) return
    onChange({ ...slot, [slotIndex]: date })
    // setFormValues(values => {
    //   const newValues = cloneDeep(values)
    //   newValues.availability[day][index][slotIndex] = date
    //   return newValues
    // })
  }
  let dayStr = first ? dayLocales["fr"][day] : ""
  if (dayStr && isMobile) {
    dayStr = dayStr.substring(0, 3)
  }
  return (
    <>
      {isMobile ? (
        <TableRow style={{ verticalAlign: "bottom" }}>
          <TableCell span={3} style={{ border: "none", paddingLeft: 0 }}>
            <Typography variant="h6">{dayStr}</Typography>
          </TableCell>
        </TableRow>
      ) : null}
      <TableRow style={{ verticalAlign: "bottom" }}>
        {isMobile ? null : (
          <TableCell style={{ ...borderStyle, paddingLeft: 0 }}>
            <Typography variant="h6">{dayStr}</Typography>
          </TableCell>
        )}
        <TableCell style={borderStyle}>
          {slot && (
            <Box p={1} pl={0}>
              <TimePicker
                label="Début"
                value={slot[0]}
                onChange={handleTimeChange(0)}
              />
            </Box>
          )}
        </TableCell>
        <TableCell style={borderStyle}>
          {slot && (
            <Box p={1}>
              <TimePicker
                label="Fin"
                value={slot[1]}
                onChange={handleTimeChange(1)}
              />
            </Box>
          )}
        </TableCell>
        <TableCell style={{ ...borderStyle, paddingRight: 0, paddingLeft: 0 }}>
          <IconButton
            color="primary"
            aria-label="add to shopping cart"
            onClick={slot ? handleRemoveSlot : handleNewSlot}
            edge="start"
            style={{
              padding: 0,
              paddingBottom: 10,
              paddingTop: 10,
              marginLeft: 0,
            }}
          >
            {slot ? <RemoveCircleIcon /> : <AddCircleIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  )
}

export default function ShopAvailablityForm({
  // availability,
  // setFormValues,
  isMobile,
  control,
}) {
  const classes = useStyles()
  return (
    <TableContainer style={{ width: undefined }}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            {isMobile ? null : <TableCell />}
          </TableRow>
          {weekDays.map(day => (
            <WeekDay
              key={day}
              day={day}
              // daySlots={availability[day]}
              // setFormValues={setFormValues}
              isMobile={isMobile}
              control={control}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
