import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableRow from "@material-ui/core/TableRow"
import { Box, IconButton, Typography } from "@material-ui/core"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import TimePicker from "./TimePicker"
import cloneDeep from "clone-deep"
import { useDispatch } from "react-redux"
import { API, graphqlOperation } from "aws-amplify"
import { updateUser } from "../app/user"

var equal = require("deep-equal")

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
})

const UPDATE_USER = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      selectedCalendars
    }
  }
`

const makeAvailabilityDate = str => {
  const [hours, minutes] = str.split(":")
  const d = new Date()
  d.setHours(parseInt(hours, 10))
  d.setMinutes(parseInt(minutes, 10))
  return d
}

const dayLocales = {
  fr: {
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche",
  },
}

const weekDays = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
]

// const getRows = availability => {
//   if (!availability)
//     return {
//       Monday: [[makeAvailabilityDate("08:00"), makeAvailabilityDate("10:00")]],
//       Tuesday: [],
//       Wednesday: [],
//       Thursday: [],
//       Friday: [],
//       Saturday: [],
//       Sunday: [],
//     }
// }

const WeekDay = ({ day, daySlots = [], setFormValues, owned, isMobile }) => {
  return (
    <>
      {daySlots.map((slot, index) => (
        <Slot
          key={index}
          index={index}
          first={index === 0}
          day={day}
          slot={slot}
          setFormValues={setFormValues}
          owned={owned}
          isMobile={isMobile}
        />
      ))}
      <Slot
        first={daySlots.length === 0}
        last
        day={day}
        setFormValues={setFormValues}
        owned={owned}
        isMobile={isMobile}
      />
    </>
  )
}

const Slot = ({
  first,
  last,
  day,
  slot,
  index,
  setFormValues,
  owned,
  isMobile,
}) => {
  const borderStyle = last ? { padding: 0 } : { border: "none", padding: 0 }
  const handleNewSlot = () =>
    setFormValues(values => {
      const newValues = cloneDeep(values)
      newValues[day].push([
        makeAvailabilityDate("09:00"),
        makeAvailabilityDate("18:00"),
      ])
      return newValues
    })
  const handleRemoveSlot = () =>
    setFormValues(values => {
      const newValues = cloneDeep(values)
      newValues[day].splice(index, 1)
      return newValues
    })
  const handleTimeChange = slotIndex => (date, value) => {
    if (!value || value.includes("_")) return
    setFormValues(values => {
      const newValues = cloneDeep(values)
      newValues[day][index][slotIndex] = date
      return newValues
    })
  }
  let dayStr = first ? dayLocales["fr"][day] : ""
  return (
    <>
      {isMobile ? (
        <TableRow style={{ verticalAlign: "bottom" }}>
          <TableCell colspan={3} style={{ border: "none", paddingLeft: 0 }}>
            <Typography variant="h6">{dayStr}</Typography>
          </TableCell>
        </TableRow>
      ) : null}
      <TableRow style={{ verticalAlign: "bottom" }}>
        {isMobile ? null : (
          <TableCell style={{ ...borderStyle, paddingLeft: 0 }}>
            <Typography variant="h6">{dayStr}</Typography>
          </TableCell>
        )}
        <TableCell style={borderStyle}>
          {slot && (
            <Box p={1} pl={0}>
              <TimePicker
                disabled={!owned}
                label="Début"
                value={slot[0]}
                onChange={handleTimeChange(0)}
              />
            </Box>
          )}
        </TableCell>
        <TableCell style={borderStyle}>
          {slot && (
            <Box p={1}>
              <TimePicker
                disabled={!owned}
                label="Fin"
                value={slot[1]}
                onChange={handleTimeChange(1)}
              />
            </Box>
          )}
        </TableCell>
        {owned ? (
          <TableCell
            style={{ ...borderStyle, paddingRight: 0, paddingLeft: 0 }}
          >
            <IconButton
              color="primary"
              aria-label="add time slot"
              onClick={slot ? handleRemoveSlot : handleNewSlot}
              edge="start"
              style={{ padding: 3, marginLeft: 0 }}
            >
              {slot ? <RemoveCircleIcon /> : <AddCircleIcon />}
            </IconButton>
          </TableCell>
        ) : null}
      </TableRow>
    </>
  )
}

export default function UserAvailablityForm({
  id,
  availability,
  owned,
  isMobile,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [cloudValues, setCloudValues] = React.useState({})
  const [formValues, setFormValues] = React.useState({})
  React.useEffect(() => {
    setCloudValues(
      availability || {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      }
    )
    setFormValues(
      availability || {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      }
    )
  }, [availability])
  React.useEffect(() => {
    if (equal(cloudValues, formValues)) return
    const input = { id, availability: JSON.stringify(formValues) }
    API.graphql(
      graphqlOperation(UPDATE_USER, {
        input,
      })
    )
      .then(() => dispatch(updateUser(input)))
      .catch(err => console.warn(err))
  }, [formValues])
  console.log({
    owned,
    availability,
    cloudValues,
    formValues,
    equal: equal(cloudValues, formValues),
  })
  return (
    <TableContainer style={{ width: undefined }}>
      <Table className={classes.table} aria-label="simple table" size="small">
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            {isMobile ? null : <TableCell />}
          </TableRow>
          {weekDays.map(day => (
            <WeekDay
              key={day}
              day={day}
              daySlots={formValues[day]}
              setFormValues={setFormValues}
              owned={owned}
              isMobile={isMobile}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
