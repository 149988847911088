import React from "react"
import BookingListTable from "./BookingListTable"
import DashboardPage from "./DashboardPage"

export default function BookingList() {
  return (
    <DashboardPage title="Mes rendez-vous">
      <BookingListTable />
    </DashboardPage>
  )
}
