import {
  Avatar,
  Backdrop,
  Box,
  Button,
  makeStyles,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectUser } from "../app/user"
import { fetchUserData } from "./AuthListener"
import { selectCognitoUser } from "../app/cognitoUser"
import { restAuth } from "../services/rest"
import HervePaper from "./HervePaper"
import DashboardPage from "./DashboardPage"
import CustomChip from "./CustomChip"
import Alert from "@material-ui/lab/Alert"
import LoadingBackdrop from "./LoadingBackDrop"

const titles = {
  Collect: "Click & collect",
  Visio: "Appels vidéos",
  Physical: "Rendez-vous physiques",
  Phone: "Rendez-vous téléphoniques",
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: 0,
    background: theme.palette.background.main,
    // color: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  title: {
    margin: theme.spacing(2),
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    // color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
  },
  chip: {
    avatar: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  chipsContainer: {
    marginTop: theme.spacing(1),
    "& > *": {
      margin: theme.spacing(2),
    },
    margin: -theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  htmlContainer: {
    display: "flex",
    borderTop: "1px solid",
    paddingTop: 10,
    flex: 1,
    flexDirection: "column",
    overflowY: "auto",
  },
}))

export default function Attributions() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const cognitoUser = useSelector(selectCognitoUser)
  const user = useSelector(selectUser)
  const [loading, setLoading] = React.useState(false)
  const [resources, setResources] = React.useState([])
  const [pickList, setPickList] = React.useState()
  const [pickTypeName, setPickTypeName] = React.useState()
  const { planCanVisio, payedVideoSeats } = user ? user.projects.items[0] : {}
  console.log({ user })
  React.useEffect(() => {
    if (!user) return
    let r = [...user.projects.items[0].resources.items].map(
      ({ id, resource }) => ({
        id,
        resource: {
          ...resource,
          bookDuration: JSON.parse(resource.bookDuration)["minutes"],
        },
      })
    )
    r.sort((a, b) => {
      if (a.resource.resourceType < b.resource.resourceType) return -1
      if (b.resource.resourceType < a.resource.resourceType) return 1
      return 0
    })
    setResources(r)
  }, [user])
  const addWorker = (r, resourceTypeName) => () => {
    const resourceWorkersIDs = r.resource.workUsers.items.map(w => w.user.id)
    const workerList = user.ownedUsers.items
      .filter(u => !resourceWorkersIDs.includes(u.id))
      .map(u => ({ ...u, resourceID: r.resource.id }))
    workerList.sort((a, b) => {
      if (!!a.calendarAccess < b.calendarAccess) return 1
      if (!!a.calendarAccess > b.calendarAccess) return -1
      return 0
    })
    setPickTypeName(resourceTypeName)
    setPickList(workerList)
    console.log({ workerList, resourceWorkersIDs })
  }
  const handlePick = data => () => {
    console.log("picked", { data })
    restAuth("post", "/auth/addResourceWorker", data)
      .then(response => {
        console.log("worker added to resource", response)
        fetchUserData(cognitoUser, dispatch)
      })
      .catch(err => console.warn(err))
  }
  const updateResourceDuration = ({ id, bookDuration }) => async () => {
    setLoading(true)
    await restAuth("post", "/auth/updateResourceDuration", {
      id,
      bookDuration: JSON.stringify({ minutes: bookDuration }),
    })
      .then(response => {
        console.log("resource duration updated", response)
        fetchUserData(cognitoUser, dispatch)
      })
      .catch(err => console.warn(err))
      .finally(() => setLoading(false))
  }
  const handleDelete = resourceWorker => () =>
    restAuth("post", "/auth/deleteResourceWorker", { id: resourceWorker.id })
      .then(response => {
        console.log("worker removed from resource", response)
        fetchUserData(cognitoUser, dispatch)
      })
      .catch(err => console.warn(err))
  console.log({ resources })
  return (
    <DashboardPage title="Services">
      <div style={{ overflowY: "auto" }}>
        {resources.map((r, index) =>
          r.resource.resourceType !== "Collect" ? (
            <HervePaper key={r.id} title={titles[r.resource.resourceType]}>
              {r.resource.resourceType === "Visio" && !planCanVisio ? (
                <Alert severity="info">
                  Pour proposer ce service, mettez à jour votre plan
                </Alert>
              ) : (
                <>
                  <Alert severity="info">
                    {r.resource.workUsers.items.length > 0
                      ? "Pour ne plus proposer ce service, retirez les vendeurs affectés à cette catégorie"
                      : "Pour proposer ce service, affectez-y un vendeur"}
                  </Alert>
                  {r.resource.resourceType === "Visio" &&
                  payedVideoSeats === r.resource.workUsers.items.length ? (
                    <Box mt={2}>
                      <Alert severity="info">
                        Pour permettre à vos clients de prendre des rendez-vous
                        vidéo avec un nouveau conseiller, vous devez mettre à
                        jour votre plan
                      </Alert>
                    </Box>
                  ) : null}
                </>
              )}
              {r.resource.workUsers.items.length > 0 && (
                <div className={classes.chipsContainer}>
                  {r.resource.workUsers.items.map(w => (
                    <CustomChip
                      size={1.5}
                      // className={classes.chip}
                      key={w.id}
                      avatar={
                        <Avatar
                          // className={classes.avatar}
                          alt={`${w.user.given_name[0]}${w.user.family_name[0]}`}
                          src={w.user.image || w.user.picture}
                        />
                      }
                      label={`${w.user.given_name} ${w.user.family_name}`}
                      onDelete={handleDelete(w)}
                    />
                  ))}
                </div>
              )}

              <Button
                variant="contained"
                color="primary"
                onClick={addWorker(r, titles[r.resource.resourceType])}
                disabled={r.resource.resourceType === "Visio" && !planCanVisio}
                style={{
                  marginTop: 20,
                  alignSelf: "flex-start",
                }}
              >
                Ajouter un vendeur conseiller
              </Button>
              {r.resource.workUsers.items.length > 0 ? (
                <Box mt={3}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    onChange={e => {
                      e.persist()
                      const newResources = [...resources]
                      newResources[index]["resource"]["bookDuration"] =
                        e.target.value
                      setResources(newResources)
                    }}
                    label="Durée des créneaux (minutes)"
                    helperText="Durée minimale : 5 minutes"
                    type="number"
                    min={5}
                    value={r.resource.bookDuration}
                  />
                  <Box mt={2} />
                  <Button
                    // fullWidth
                    variant="contained"
                    color="primary"
                    onClick={updateResourceDuration(r.resource)}
                  >
                    Appliquer la nouvelle durée
                  </Button>
                </Box>
              ) : null}
            </HervePaper>
          ) : null
        )}
      </div>
      <Backdrop
        className={classes.backdrop}
        open={!!pickList}
        onClick={() => setPickList()}
      >
        <Paper className={classes.paper} elevation={5}>
          <Typography variant="h4" style={{ whiteSpace: "pre-wrap" }}>
            {`Sélectionnez l'employé\nà affecter au service`}
          </Typography>
          <Typography variant="h4" style={{ fontWeight: "bold" }}>
            {pickTypeName}
          </Typography>
          {pickList &&
            pickList.map(data => {
              const {
                given_name,
                family_name,
                shopAdmin,
                image,
                picture,
              } = data
              return (
                <Paper
                  elevation={5}
                  key={data.id}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    margin: 10,
                  }}
                  disabled={!data.calendarAccess}
                  component={Button}
                  onClick={handlePick(data)}
                >
                  <Avatar src={image || picture} />
                  <Typography
                    style={{
                      display: "flex",
                      flex: 1,
                      margin: 10,
                      textTransform: "none",
                    }}
                  >{`${given_name} ${family_name}${shopAdmin ? " (vous)" : ""}${
                    !data.calendarAccess ? " (calendrier non connecté)" : ""
                  }`}</Typography>
                </Paper>
              )
            })}
        </Paper>
      </Backdrop>
      <LoadingBackdrop open={!!loading} />
    </DashboardPage>
  )
}
