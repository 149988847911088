import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectUser } from "../app/user"
import HervePaper from "./HervePaper"
import useIsMobile from "../utils/useIsMobile"
import {
  Box,
  Checkbox,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  makeStyles,
  Slide,
  TextField,
  Typography,
} from "@material-ui/core"
import { restAuth } from "../services/rest"
import AddCircleIcon from "@material-ui/icons/AddCircle"
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle"
import LoadingBackdrop from "./LoadingBackDrop"
import DashboardPage from "./DashboardPage"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import ReportProblemIcon from "@material-ui/icons/ReportProblem"
import SecondaryButton from "./SecondaryButton"
import BillingTabs from "./BillingTabs"
import PrimaryButton from "./PrimaryButton"
import { Alert } from "@material-ui/lab"
import { fetchUserData } from "./AuthListener"
import { selectCognitoUser } from "../app/cognitoUser"

const useStyles = makeStyles(theme => ({
  pageRoot: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: 0,
    background: theme.palette.background.main,
    // color: theme.palette.primary.main,
  },
  pageTitle: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  button: {
    marginTop: theme.spacing(2),
    alignSelf: "flex-start",
  },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function Billing() {
  const isMobile = useIsMobile()
  const user = useSelector(selectUser)
  const {
    payedSeats,
    charges_enabled,
    capabilities,
    planCanSell,
    payedVideoSeats,
  } = user?.projects?.items[0] || {}
  const usedVideoSeats =
    user?.projects?.items[0].resources.items
      .filter(item => item.resource.resourceType === "Visio")
      .pop().resource.workUsers.items.length || 0
  const usedCalendarSeats =
    user?.ownedUsers.items.filter(u => u.calendarAccess).length || 0
  const dispatch = useDispatch()
  const cognitoUser = useSelector(selectCognitoUser)
  //   const [subscriptions, setSubscriptions] = React.useState()
  const [loading, setLoading] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [newPayedSeats, setNewPayedSeats] = React.useState(payedSeats)
  const [newPayedVideoSeats, setNewPayedVideoSeats] = React.useState(
    payedVideoSeats
  )
  const [newPlanCanSell, setNewPlanCanSell] = React.useState(!!planCanSell)
  const [error, setError] = React.useState()

  React.useEffect(() => {
    setNewPlanCanSell(planCanSell)
    setNewPayedVideoSeats(payedVideoSeats)
    setNewPayedSeats(payedSeats)
  }, [user])
  //   const dispatch = useDispatch()
  //   const [cloudValues, setCloudValues] = React.useState({})
  //   const [formValues, setFormValues] = React.useState({})

  const fetchAndNavigate = path => async () => {
    setLoading(true)
    try {
      await restAuth("get", path).then(({ url, error }) => {
        if (error) throw Error(error)
        window.location = url
      })
    } catch (error) {
      setLoading(false)
      console.warn(error)
    }
  }
  if (!user) return <LoadingBackdrop open />

  const onPaymentMethodUpdateClick = fetchAndNavigate("/auth/updateBill")
  const onPlanUpdateClick = () => setOpenDialog(true)

  const onConnectedAccountClick = fetchAndNavigate("/auth/accountlink")

  const handleCanSellChange = event => setNewPlanCanSell(event.target.checked)

  console.log({ capabilities })
  const planCost =
    (payedSeats - 1) * 29 + payedVideoSeats * 9 + Number(planCanSell) * 3
  const newPlanCost =
    (newPayedSeats - 1) * 29 +
    newPayedVideoSeats * 9 +
    Number(newPlanCanSell) * 3
  const { card_payments, transfers } = JSON.parse(
    capabilities && capabilities !== "null" ? capabilities : "{}"
  )
  const handlePlanUpdate = async () => {
    setLoading(true)
    setOpenDialog(false)
    const response = await restAuth("post", "/auth/updatePlan", {
      newPayedSeats,
      newPayedVideoSeats,
      newPlanCanSell,
    })
    console.log(JSON.stringify({ response }, null, 2))
    setTimeout(async () => {
      await fetchUserData(cognitoUser, dispatch)
      if (!response.data || response.data.status !== "active") {
        setError("Payment method needs to be updated")
      }
      setLoading(false)
    }, 5000)
  }
  const stripeOk =
    charges_enabled && card_payments === "active" && transfers === "active"
  const planFormUpdated =
    planCanSell !== newPlanCanSell ||
    payedVideoSeats !== newPayedVideoSeats ||
    payedSeats !== newPayedSeats
  console.log({
    isMobile,
    charges_enabled,
    capabilities,
    planCanSell,
    payedVideoSeats,
    payedSeats,
    newPlanCanSell,
    newPayedVideoSeats,
    newPayedSeats,
    usedVideoSeats,
    usedCalendarSeats,
  })
  return (
    <DashboardPage title="Plan">
      <BillingTabs
        payedSeats={payedSeats}
        planCanSell={planCanSell}
        planCanVisio={payedVideoSeats > 0}
        payedVideoSeats={payedVideoSeats}
        stripeOk={stripeOk}
        onStripePress={onConnectedAccountClick}
        onInfoClick={onPlanUpdateClick}
        onPaymentMethodUpdateClick={onPaymentMethodUpdateClick}
      />
      <Dialog
        fullScreen={!!isMobile}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        TransitionComponent={Transition}
      >
        <Box
          flex={1}
          display="flex"
          flexDirection="column"
          mx={1}
          mb={2}
          justifyContent="space-between"
        >
          <Box mt={2} display="flex" flexDirection="column">
            <Typography variant="h6">Calendriers connectés</Typography>
            <Typography variant="caption">
              La première connexion de calendrier est gratuite ; chaque
              connexion supplémentaire est facturée 29 € TTC / mois
            </Typography>
            <Box flexDirection="row" display="flex" alignItems="center">
              <Box
                flex={1}
                flexDirection="row"
                display="flex"
                alignItems="center"
              >
                <IconButton
                  edge="start"
                  onClick={() =>
                    setNewPayedSeats(value =>
                      Math.max(1, usedCalendarSeats, value - 1)
                    )
                  }
                >
                  <RemoveCircleIcon />
                </IconButton>
                <Typography>{newPayedSeats || payedSeats}</Typography>
                <IconButton
                  disabled={(newPayedSeats || payedSeats) === 10}
                  onClick={() =>
                    setNewPayedSeats(value => Math.min(10, value + 1))
                  }
                >
                  <AddCircleIcon />
                </IconButton>
              </Box>
              <Typography>
                {`${((newPayedSeats || payedSeats) - 1) * 29} € TTC / mois`}
              </Typography>
            </Box>
            {/* <TextField
              type="number"
              inputProps={{
                min: "1",
                max: "10",
              }}
              variant="outlined"
              value={newPayedSeats || payedSeats}
              label="Connexions de calendriers"
              onChange={handlePayedSeatsChange}
            /> */}
            {newPayedSeats == 10 ? (
              <>
                <Box mt={2} />
                <Alert severity="info">
                  Cette offre est limitée à 10 calendriers.{" "}
                  <a href="https://herve.shop">Contactez-nous</a> pour
                  bénéficier d'une offre personnalisée
                </Alert>
              </>
            ) : null}
          </Box>

          <Divider />

          <Box mt={2} display="flex" flexDirection="column">
            <Typography variant="h6">Rendez-vous par vidéo</Typography>
            <Typography variant="caption">
              Cette option est facturée 5 € TTC / mois / vendeur
            </Typography>
            <Box flexDirection="row" display="flex" alignItems="center">
              <Box
                flex={1}
                flexDirection="row"
                display="flex"
                alignItems="center"
              >
                <IconButton
                  edge="start"
                  onClick={() =>
                    setNewPayedVideoSeats(value =>
                      Math.max(usedVideoSeats, value - 1)
                    )
                  }
                >
                  <RemoveCircleIcon />
                </IconButton>
                <Typography>{newPayedVideoSeats}</Typography>
                <IconButton
                  disabled={newPayedVideoSeats === 10}
                  onClick={() =>
                    setNewPayedVideoSeats(value =>
                      Math.max(usedVideoSeats, Math.min(10, value + 1))
                    )
                  }
                >
                  <AddCircleIcon />
                </IconButton>
              </Box>
              <Typography>{`${
                newPayedVideoSeats * 9
              } € TTC / mois`}</Typography>
            </Box>
            {/* <TextField
              type="number"
              inputProps={{
                min: "1",
                max: "10",
              }}
              variant="outlined"
              value={newPayedSeats || payedSeats}
              label="Connexions de calendriers"
              onChange={handlePayedSeatsChange}
            /> */}
            {newPayedVideoSeats == 10 ? (
              <>
                <Box mt={2} />
                <Alert severity="info">
                  Cette offre est limitée à 10 vendeurs.{" "}
                  <a href="https://herve.shop">Contactez-nous</a> pour
                  bénéficier d'une offre personnalisée
                </Alert>
              </>
            ) : null}
          </Box>

          <Divider />

          <Typography variant="h6">Paiement en ligne</Typography>
          <Typography variant="caption">
            Facturation forfaitaire pour l'ensemble du magasin
          </Typography>
          {/* <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flex={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newPlanCanVisio}
                    onChange={handleCanVisioChange}
                    name="newPlanCanVisio"
                  />
                }
                label="Proposer les rendez-vous par vidéo (29 € TTC / mois)"
              />
            </Box>
          </Box> */}
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box display="flex" flex={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newPlanCanSell}
                    onChange={handleCanSellChange}
                    name="newPlanCanSell"
                  />
                }
                label="Proposer le paiement en ligne (3 € TTC / mois)"
              />
            </Box>
            {/* {newPlanCanSell ? <Typography>29 € TTC / mois</Typography> : null} */}
          </Box>
          <Divider />
          <Box display="flex" flexDirection="row" alignItems="flex-end">
            <Box display="flex" flex={1}>
              <Typography variant="h6">Total</Typography>
            </Box>
            <Typography>{`${
              planFormUpdated ? newPlanCost : planCost
            } € TTC / mois`}</Typography>
          </Box>
          <SecondaryButton
            onClick={
              planFormUpdated ? handlePlanUpdate : () => setOpenDialog(false)
            }
          >
            {planFormUpdated ? `Mettre à jour mon plan` : `Conserver mon plan`}
          </SecondaryButton>
        </Box>
      </Dialog>
      <Dialog
        open={!!error}
        onClose={() => setError()}
        TransitionComponent={Transition}
      >
        <Box m={2} flexDirection="column" display="flex">
          <Alert severity="warning">
            Votre plan n'a pas pu être mis à jour
          </Alert>
          <Box mt={2} />
          <Typography>
            Votre moyen de paiement doit être mis à jour pour finaliser le
            changement de plan. En cas de difficulté, contactez le support
            technique.
          </Typography>
        </Box>
      </Dialog>
      <LoadingBackdrop open={!!loading} />
    </DashboardPage>
  )
}
