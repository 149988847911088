import { Box, Grid, IconButton, Typography } from "@material-ui/core"
import React from "react"
import { useSelector } from "react-redux"
import { selectCognitoUser } from "../app/cognitoUser"
import { selectUser } from "../app/user"
import DashboardPage from "./DashboardPage"
import HervePaper from "./HervePaper"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { Link } from "gatsby"
import LoadingBackdrop from "./LoadingBackDrop"

const Step = ({ label, isCompleted, isNextStep, to }) => {
  console.log({ to })
  return (
    <Box flexDirection="row" display="flex" alignItems="center" height="100%">
      {isCompleted ? (
        <CheckCircleIcon style={{ color: "green" }} />
      ) : (
        <RadioButtonUncheckedIcon
          style={!isNextStep ? { color: "gray" } : {}}
        />
      )}
      <Box ml={2} alignItems="center">
        <Typography style={!isNextStep ? { color: "gray" } : {}}>
          {label}
        </Typography>
      </Box>
      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="flex-end"
      >
        <IconButton
          disabled={isCompleted || !isNextStep}
          component={Link}
          to={`/dashboard/${to}`}
        >
          {!isCompleted && isNextStep ? (
            <ArrowForwardIcon
              style={{ color: !isCompleted && isNextStep ? "#13171C" : "gray" }}
            />
          ) : null}
        </IconButton>
      </Box>
    </Box>
  )
}

const steps = [
  {
    index: 0,
    label: "Créez et paramétrez votre magasin",
    isCompleted: (_cognitoUser, _user, project) => !!project.slug,
    to: "project",
  },
  {
    index: 1,
    label: "Synchronisez un calendrier",
    isCompleted: (_cognitoUser, user, _project) =>
      user.ownedUsers.items.filter(u => !!u.calendarAccess).length > 0,
    to: "resources",
  },
  {
    index: 2,
    label: "Affectez un conseiller",
    isCompleted: (_cognitoUser, user, _project) =>
      user.ownedResources.items.filter(item => item.workUsers.items.length > 0)
        .length > 0,
    to: "attributions",
  },
  // {
  //   index: 3,
  //   label: "Activez le paiement en ligne de vos clients",
  //   isCompleted: (_cognitoUser, _user, project) => {
  //     const capabilities = JSON.parse(project.capabilities || "{}")
  //     return (
  //       capabilities?.card_payments === "active" &&
  //       capabilities?.transfers === "active"
  //     )
  //   },
  //   to: "billing",
  // },
]

export default function AdminDashboard() {
  const cognitoUser = useSelector(selectCognitoUser)
  const user = useSelector(selectUser)
  if (!user || !cognitoUser) return <LoadingBackdrop open />
  const isShopAdmin = cognitoUser?.groups?.includes("ShopsAdmin")
  const project = user?.projects?.items[0]
  // const connectedCalendars = isShopAdmin
  //   ? user.ownedUsers.items.filter(u => !!u.calendarAccess).length
  //   : Number(!!user.calendarAccess)
  // const activeAffectations = user.ownedResources.items.filter(
  //   item => item.workUsers.items.length > 0
  // ).length
  // const isShopAdminAndSeller =
  //   isShopAdmin &&
  //   user.workResources.items.filter(item => item.userID === user.id)
  // const capabilities = isShopAdmin
  //   ? JSON.parse(project.capabilities || "{}")
  //   : null
  const stepsValues = steps.map(step => ({
    ...step,
    isCompleted: step.isCompleted(cognitoUser, user, project),
  }))
  // const completedSteps = stepsValues
  //   .filter(step => step.isCompleted)
  //   .map(step => step.index)
  const nextSteps = stepsValues
    .filter(step => !step.isCompleted)
    .map(step => step.index)
  const nextStep = nextSteps.length > 0 ? nextSteps[0] : undefined
  console.log({ nextStep, stepsValues, nextSteps })
  return (
    <DashboardPage title="Tableau de bord">
      {isShopAdmin && (
        <HervePaper title="Première prise en main">
          <Grid container spacing={2}>
            {stepsValues
              .filter(step => step.isCompleted)
              .map(step => (
                <Grid item xs={12} key={step.label} style={{ minHeight: 48 }}>
                  <Step
                    {...step}
                    isCompleted={step.isCompleted}
                    isNextStep={step.index === nextStep}
                  />
                </Grid>
              ))}
            {stepsValues
              .filter(step => !step.isCompleted)
              .map(step => (
                <Grid item xs={12} key={step.label} style={{ minHeight: 48 }}>
                  <Step
                    {...step}
                    isCompleted={step.isCompleted}
                    isNextStep={step.index === nextStep}
                  />
                </Grid>
              ))}
          </Grid>
          <Box my={1} />
        </HervePaper>
      )}

      {/* {isShopAdmin && project.slug ? (
        <HervePaper title="✅ La page de votre magasin est publiée !" />
      ) : null}
      {isShopAdmin && connectedCalendars > 0 ? (
        <HervePaper
          title={`✅ Vous avez connecté ${connectedCalendars} calendrier(s)`}
        />
      ) : null}
      {!isShopAdmin && connectedCalendars ? (
        <HervePaper title="✅ Vous avez connecté votre calendrier" />
      ) : null}
      {isShopAdmin && activeAffectations ? (
        <HervePaper
          title={`✅ Vous avez affecté des conseillers à ${activeAffectations} services`}
        />
      ) : null}
      {isShopAdminAndSeller && user.shortDescription ? (
        <HervePaper title="✅ Vous avez renseigné votre profil" />
      ) : null}
      {isShopAdmin &&
      capabilities?.card_payments === "active" &&
      capabilities?.transfers === "active" ? (
        <HervePaper title="✅ Le paiement en ligne est activé" />
      ) : null} */}
    </DashboardPage>
  )
}
