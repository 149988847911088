import React from "react"
import { API, graphqlOperation } from "aws-amplify"
import LoadingBackdrop from "./LoadingBackDrop"
import { makeStyles } from "@material-ui/core"
import AdminBookingContent from "./AdminBookingContent"
import { restAuth } from "../services/rest"
import Footer from "./Footer"
// import { updateBooking } from "../graphql/mutations"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    // height: "100vh",
    flexDirection: "column",
    width: "100%",
    // paddingTop: theme.spacing(2),
    // paddingBottom: theme.spacing(2),
    paddingRight: 0,
    // background: "blue",
    background: theme.palette.background.main,
    // color: theme.palette.primary.main,
    // border: "1px solid red",
  },
}))

const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      canSell
      resourceID
      props
      start
      end
      bookReason
      bookType
      status
      recipe
      totalAmount
      deliveryPrice
      canShip
      hasSell
      customerEmail
      customerGivenName
      customerName
      customerPhone
      createdAt
      updatedAt
      owner
      meetUrl
      payment_status
      shipping {
        address {
          city
          country
          line1
          line2
          postal_code
          state
        }
        name
      }
    }
  }
`

export default function AdminBooking({ id }) {
  const classes = useStyles()
  const [booking, setBooking] = React.useState({ loading: true })
  const setLoading = loading =>
    setBooking(b => ({
      ...b,
      loading,
    }))
  const setBookingData = data =>
    setBooking(b => ({
      ...b,
      data,
      loading: false,
      error: null,
    }))
  const setBookingError = error =>
    setBooking(b => ({
      ...b,
      loading: false,
      error,
    }))
  const { loading, error, data } = booking
  const fetchData = async () => {
    setLoading(true)
    await API.graphql(
      graphqlOperation(getBooking, {
        id,
      })
    )
      .then(response => setBookingData(response.data.getBooking))
      .catch(err => {
        console.warn(err)
        setBookingError(err)
      })
  }
  const updateBookingItem = props => () => {
    const input = { id, ...props }
    console.log({ input })
    setLoading(true)
    restAuth("post", "/auth/updateBookingStatus", {
      ...input,
      oldStatus: data.status,
    })
      .then(() => setBookingData({ ...data, ...props }))
      .finally(() => setLoading(false))
    // API.graphql(
    //   graphqlOperation(updateBooking, {
    //     input,
    //   })
    // ).then(() => setBookingData({ ...data, ...input }))
  }
  React.useEffect(() => {
    fetchData()
  }, [id])
  return (
    <div className={classes.root}>
      {error ? "Une erreur a été rencontrée" : null}
      {booking.data ? (
        <AdminBookingContent
          booking={data}
          reload={fetchData}
          updateBookingItem={updateBookingItem}
          setLoading={setLoading}
          setBookingData={setBookingData}
        />
      ) : null}
      <Footer />
      <LoadingBackdrop open={loading} />
    </div>
  )
}
