import { Box, makeStyles } from "@material-ui/core"
import config from "../aws-exports"
import React from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import { selectCognitoUser } from "../app/cognitoUser"
import { selectUser } from "../app/user"
import { Storage } from "aws-amplify"
import HervePaper from "./HervePaper"
import DashboardPage from "./DashboardPage"
import { restAuth } from "../services/rest"
import { fetchUserData } from "./AuthListener"
import SimpleSnack from "./SimpleSnack"
import Form from "./Form"
import { AvatarInput, TextInput } from "./Input"
import SecondaryButton from "./SecondaryButton"

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",")
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) u8arr[n] = bstr.charCodeAt(n)
  return new File([u8arr], filename, { type: mime })
}

function dirtyValues(dirtyFields, allValues) {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) return allValues
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  )
}

const inputs = [
  // {
  //   name: "email",
  //   label: "Email",
  //   disabled: true,
  //   className: "textInput",
  // },
  {
    name: "image",
    Component: AvatarInput,
  },
  {
    name: "family_name",
    label: "Nom",
    className: "textInput",
  },
  {
    name: "given_name",
    label: "Prénom",
    className: "textInput",
  },
  {
    name: "shortDescription",
    label: "Spécialité(s), description courte",
    className: "textInput",
    inputProps: { maxLength: 50 },
  },
]

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config

const cloneDeep = require("clone-deep")

const formValuesToApiValues = async formValues => {
  const apiValues = cloneDeep(formValues)
  if (apiValues.image && apiValues.image.startsWith("data:")) {
    const file = dataURLtoFile(apiValues.image, "test.jpg")
    const extension = file.name.split(".").pop()
    const { type: mimeType } = file
    const key = `images/${apiValues.id}-avatar.${extension}`
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
    try {
      await Storage.put(key, file, {
        level: "public",
        contentType: mimeType,
      })
      apiValues.image = url
      formValues.image = url
    } catch (error) {
      console.warn("file upload failed", error)
    }
  }
  return apiValues
}

const useStyles = makeStyles(theme => ({
  pageRoot: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: 0,
    background: theme.palette.background.main,
    // color: theme.palette.primary.main,
  },
  spacedChildren: {
    width: "100%",
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  pageTitle: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
  textInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

export default function EmployeeAccount({ id }) {
  const [success, setSuccess] = React.useState()
  const classes = useStyles()
  const dispatch = useDispatch()
  const [cloudValues, setCloudValues] = React.useState()
  const user = useSelector(selectUser)
  const cognitoUser = useSelector(selectCognitoUser)
  const { email } = cognitoUser

  React.useEffect(() => {
    if (!user || !email) return
    console.log({ user })
    const { family_name, given_name, image, picture, shortDescription } = user
    setCloudValues({
      family_name,
      given_name,
      image: image ? `${image}?${Date.now()}` : picture,
      shortDescription,
      email,
    })
  }, [user, email])

  const onSubmit = ({ dirtyFields, reset }) => async data => {
    const dirty = dirtyValues(dirtyFields, data)
    console.log("data:", data, dirty)
    const input = await formValuesToApiValues({ id, ...dirty })
    console.log({ input })
    await restAuth("post", "/auth/updateProfile", input)
    await fetchUserData(cognitoUser, dispatch)
    if (input.image) {
      input.image = `${input.image}?${Date.now()}`
    }
    reset({ ...data, ...input })
    setSuccess(true)
  }
  console.log({ cloudValues })
  return (
    <DashboardPage title="Profil">
      <HervePaper>
        <Box className={classes.spacedChildren}>
          {cloudValues ? (
            <Form onSubmit={onSubmit} defaultValues={cloudValues}>
              {inputs.map(({ className, Component = TextInput, ...props }) => (
                <Component
                  key={props.name}
                  className={classes[className]}
                  {...props}
                />
              ))}
              <SecondaryButton
                type="submit"
                variant="contained"
                disabled={false}
              >
                Appliquer les modifications
              </SecondaryButton>
            </Form>
          ) : null}
        </Box>
        <SimpleSnack
          handleClose={() => setSuccess()}
          open={!!success}
          severity="success"
          message="Les modifications ont été prises en compte "
        />
      </HervePaper>
    </DashboardPage>
  )
}
