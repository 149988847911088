import { Button, makeStyles, Paper, Typography } from "@material-ui/core"
import React from "react"
import { useSelector } from "react-redux"
import { selectCognitoUser } from "../app/cognitoUser"
import { selectUser } from "../app/user"
import { navigate } from "gatsby"
import { useLocation } from "@reach/router"

const useStyles = isMobile =>
  makeStyles(theme => ({
    instructionPaper: {
      alignSelf: "flex-start",
      alignItems: "center",
      marginLeft: theme.spacing(isMobile ? 1 : 2),
      marginRight: theme.spacing(isMobile ? 1 : 2),
      marginBottom: theme.spacing(isMobile ? 1 : 2),
      padding: 10,
      display: "flex",
      flexDirection: "row",
      backgroundColor: theme.palette.secondary.main,
    },
    invertedButton: {
      background: "white",
      color: theme.palette.secondary.main,
      marginLeft: theme.spacing(3),
    },
  }))

const Hint = ({ text, buttonText, onClick, path, isMobile }) => {
  const classes = useStyles(isMobile)()
  const location = useLocation()
  if (location.pathname === path) return null
  return (
    <Paper className={classes.instructionPaper}>
      <Typography
        variant="h6"
        style={{ color: "white", whiteSpace: "pre-wrap" }}
      >
        {text}
      </Typography>
      <Button
        className={classes.invertedButton}
        variant="contained"
        onClick={onClick}
      >
        {buttonText}
      </Button>
    </Paper>
  )
}

export default function AdminHint({ isMobile }) {
  const cognitoUser = useSelector(selectCognitoUser)
  const user = useSelector(selectUser)
  if (!user || !cognitoUser) return null
  const isShopAdmin = cognitoUser.groups?.includes("ShopsAdmin")
  const isShopEmployee = cognitoUser?.groups?.includes("ShopEmployee")
  const capabilities = isShopAdmin
    ? JSON.parse(user.projects.items[0].capabilities || "{}")
    : null
  if (isShopAdmin && !user.projects.items[0].slug)
    return (
      <Hint
        text={`Pour commencer,\nparamétrez votre point de vente`}
        buttonText="C'est parti !"
        path="/dashboard/project"
        onClick={() => navigate("/dashboard/project")}
        isMobile={isMobile}
      />
    )
  const hasConnectedCalendars = isShopAdmin
    ? user.ownedUsers.items.filter(u => !!u.calendarAccess).length > 0
    : user.calendarAccess
  if (isShopAdmin && !hasConnectedCalendars)
    return (
      <Hint
        text={`Invitez vos employés à créer leur compte et à connecter leur calendrier ou connectez le vôtre`}
        buttonText="C'est parti !"
        path="/dashboard/resources"
        onClick={() => navigate("/dashboard/resources")}
        isMobile={isMobile}
      />
    )
  const hasAffectation =
    user.ownedResources.items.filter(item => item.workUsers.items.length > 0)
      .length > 0
  if (isShopAdmin && !hasAffectation) {
    return (
      <Hint
        text={`Affectez les employés aux services`}
        buttonText="C'est parti !"
        path="/dashboard/attributions"
        onClick={() => navigate("/dashboard/attributions")}
        isMobile={isMobile}
      />
    )
  }
  const isShopAdminAndSeller =
    isShopAdmin &&
    user.workResources.items.filter(item => item.userID === user.id)
  if (isShopAdminAndSeller && !user.shortDescription) {
    return (
      <Hint
        text={`Complétez vos informations de profil`}
        buttonText="C'est parti !"
        path="/dashboard/account"
        onClick={() => navigate("/dashboard/account")}
        isMobile={isMobile}
      />
    )
  }
  // if (
  //   isShopAdmin &&
  //   (!user.projects.items[0].charges_enabled ||
  //     !capabilities.card_payments === "active" ||
  //     !capabilities.transfers === "active")
  // ) {
  //   return (
  //     <Hint
  //       text={`Complétez les informations pour pouvoir recevoir les paiements`}
  //       buttonText="C'est parti !"
  //       path="/dashboard/billing"
  //       onClick={() => navigate("/dashboard/billing")}
  //       isMobile={isMobile}
  //     />
  //   )
  // }
  if (isShopEmployee && !user.calendarAccess) {
    return (
      <Hint
        text={`Pour commencer, connectez votre calendrier et paramétrez vos rendez-vous`}
        buttonText="C'est parti !"
        onClick={() => navigate("/dashboard/calendar")}
        isMobile={isMobile}
      />
    )
  }
  return null
}
