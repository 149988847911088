import {
  Avatar,
  Backdrop,
  Badge,
  Box,
  Button,
  TextField,
} from "@material-ui/core"
import React from "react"
import SecondaryButton from "./SecondaryButton"
import { Controller } from "react-hook-form"
import { makeStyles } from "@material-ui/core/styles"
import HervePaper from "./HervePaper"
import ImageCrop from "./ImageCrop"
import EditIcon from "@material-ui/icons/Edit"

export function TextInput({
  register,
  name,
  fullWidth = true,
  watch,
  setValue,
  ...rest
}) {
  console.log({ ...register(name) })
  const { ref, ...props } = register(name)
  return (
    <TextField
      variant="outlined"
      fullWidth={fullWidth}
      inputRef={ref}
      {...props}
      {...rest}
    />
  )
}

export function FileInputButton({ register, name, label, ...rest }) {
  const { onChange, ...props } = register(name)
  console.log({ rest })
  return (
    <SecondaryButton component="label">
      {label}
      <input
        {...props}
        {...rest}
        type="file"
        hidden
        onChange={event => {
          event.persist()
          const {
            target: { value, files },
          } = event
          const fileForUpload = files[0]
          onChange({ target: { value: fileForUpload || value, name } })
        }}
      />
    </SecondaryButton>
  )
}

const useAvatarInputStyles = makeStyles(theme => ({
  large: {
    width: theme.spacing(14),
    height: theme.spacing(14),
    margin: 0,
    padding: 0,
    borderRadius: "50%",
    overflow: "hidden",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}))

export function AvatarInput({ control, name, setValue, watch }) {
  const classes = useAvatarInputStyles()
  const [open, setOpen] = React.useState(false)
  const openPopup = () => setOpen(true)
  const closePopup = () => setOpen(false)
  const v = watch(name)
  console.log({ v })
  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <>
            <Box display="flex" justifyContent="center" mb={2}>
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <EditIcon
                    fontSize="large"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "50%",
                      overflow: "hidden",
                      padding: 3,
                      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
                    }}
                  />
                }
              >
                <Avatar
                  component={Button}
                  className={classes.large}
                  src={v}
                  onClick={openPopup}
                />
              </Badge>
            </Box>
            <Backdrop
              className={classes.backdrop}
              open={open}
              onClick={closePopup}
            >
              <Box
                flex={1}
                p={2}
                display="flex"
                flexDirection="column"
                alignSelf="stretch"
                overflow="auto"
              >
                <HervePaper
                  onClick={e => e.stopPropagation()}
                  m={2}
                  style={{
                    flex: 1,
                    overflowY: "auto",
                  }}
                >
                  <ImageCrop
                    hideCrop={closePopup}
                    onChange={image =>
                      setValue(name, image, { shouldDirty: true })
                    }
                    round
                    // formKey={crop.key}
                    // image={formValues.meta[crop.key]}
                    // formValues={formValues}
                    // setFormValues={setFormValues}
                  />
                </HervePaper>
              </Box>
            </Backdrop>
          </>
        )
      }}
    />
  )
}
