import React from "react"
import { Divider, TextField } from "@material-ui/core"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import { makeStyles } from "@material-ui/core/styles"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { Link } from "gatsby"
import StoreIcon from "@material-ui/icons/Store"
import VideoCallIcon from "@material-ui/icons/VideoCall"
import PhoneEnabledIcon from "@material-ui/icons/PhoneEnabled"

const icons = {
  Visioconférence: <VideoCallIcon style={{ margin: 0, padding: 0 }} />,
  "par téléphone": <PhoneEnabledIcon style={{ margin: 0, padding: 0 }} />,
  "au magasin": <StoreIcon style={{ margin: 0, padding: 0 }} />,
}

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: "center",
    fontSize: "20px",
    padddingLeft: 5,
    paddingRight: 5,
  },
  selectWidth: {
    width: "100%",
    marginBottom: "18px",
  },
  searchGeneralTextField: {
    marginBottom: "20px",
    marginTop: "15px",
    width: "250px",
  },
}))

const matchForm = formValue => {
  if (!formValue) {
    return () => true
  }
  const tokens = formValue.toLowerCase().match(/\S+/g)
  console.log({ tokens })
  return item => {
    const str = JSON.stringify(item).toLowerCase()
    console.log("mes str:", str)
    return tokens.every(token => str.includes(token))
  }
}

export default function TableRender({
  data,
  setFilters,
  emptyData,
  isLoading,
}) {
  const [searchStatus, setSearchStatus] = React.useState("")
  const [searchType, setSearchType] = React.useState("")
  const [searchGeneral, setSearchGeneral] = React.useState("")

  const filtered = data.filter(matchForm(searchGeneral.toLowerCase()))

  const classes = useStyles()

  return (
    <>
      <TextField
        id="outlined-basic"
        label="Rechercher"
        variant="outlined"
        value={searchGeneral}
        onChange={e => {
          e.preventDefault()
          setSearchGeneral(e.target.value)
        }}
        className={classes.searchGeneralTextField}
      />
      <table style={{ borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th className={classes.title}></th>
            <th className={classes.title}>Type</th>
            <th className={classes.title}>Début</th>
            <th className={classes.title}>Statut</th>
          </tr>
          <tr>
            <td></td>
            <td>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={searchType}
                onChange={e => {
                  e.preventDefault()
                  setFilters(currentFilter => {
                    const newFilter = currentFilter.filter(
                      item => item.field !== "bookType"
                    )
                    e.target.value &&
                      newFilter.push({
                        field: "bookType",
                        value: e.target.value,
                      })
                    return newFilter
                  })
                  setSearchType(e.target.value)
                }}
                className={classes.selectWidth}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="Visio">Visioconférence</MenuItem>
                <MenuItem value="Phone">par téléphone</MenuItem>
                <MenuItem value="Physical">au magasin</MenuItem>
              </Select>
            </td>
            <td>
              {/* <Select
                labelId="simple-select-label"
                id="simple-select"
                value={searchStart}
                onChange={e => {
                  e.preventDefault()
                  setFilters(currentFilter => {
                    const newFilter = currentFilter.filter(
                      item => item.field !== "start"
                    )
                    e.target.value &&
                      newFilter.push({ field: "start", value: e.target.value })
                    return newFilter
                  })
                  setSearchStart(e.target.value)
                }}
                className={classes.selectWidth}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value={4}>Aujourdhui</MenuItem>
                <MenuItem value="">Il y'a une semaine </MenuItem>
                <MenuItem value="">Il y'a un mois</MenuItem>
              </Select> */}
            </td>
            <td>
              <Select
                labelId="simple-select-label"
                id="simple-select"
                value={searchStatus}
                onChange={e => {
                  e.preventDefault()
                  setFilters(currentFilter => {
                    const newFilter = currentFilter.filter(
                      item => item.field !== "status"
                    )
                    e.target.value &&
                      newFilter.push({ field: "status", value: e.target.value })
                    return newFilter
                  })
                  setSearchStatus(e.target.value)
                }}
                className={classes.selectWidth}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="confirmed">Confirmé</MenuItem>
                <MenuItem value="pendingCustomerConfirmation">
                  Non confirmé
                </MenuItem>
                <MenuItem value="delivered">Délivré</MenuItem>
                <MenuItem value="waiting payment">
                  En attente de règlement
                </MenuItem>
                <MenuItem value="paid">Réglè</MenuItem>
                <MenuItem value="sent">Envoyé</MenuItem>
              </Select>
            </td>
          </tr>
        </thead>
        <tbody>
          {emptyData && !isLoading ? (
            <tr>Aucun résultat</tr>
          ) : (
            filtered.map(e => {
              return (
                <>
                  <tr key={e.id}>
                    <td
                      style={{
                        borderBottom: "1px solid rgb(200,200,200)",
                        textAlign: "center",
                        verticalAlign: "middle",
                      }}
                    >
                      <Link to={`/dashboard/booking/${e.id}`} target="_blank">
                        <VisibilityIcon />
                      </Link>
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid rgb(200,200,200)",
                        paddingTop: 5,
                        paddingBottom: 5,
                        textAlign: "center",
                        verticalAlign: "middle",
                        margin: 0,
                      }}
                    >
                      {icons[e.bookType]}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid rgb(200,200,200)",
                        whiteSpace: "nowrap",
                        verticalAlign: "middle",
                      }}
                    >
                      {e.start}
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid rgb(200,200,200)",
                        paddingLeft: 5,
                        paddingTop: 5,
                        paddingBottom: 5,
                        verticalAlign: "middle",
                      }}
                    >
                      {e.status}
                    </td>
                    <Divider />
                  </tr>
                </>
              )
            })
          )}
        </tbody>
      </table>
    </>
  )
}

//TableRender.whyDidYouRender = true
