import "date-fns"
import React from "react"
import DateFnsUtils from "@date-io/date-fns"
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import frLocale from "date-fns/locale/fr"

export default function DatePicker({ label, value, onChange }) {
  // The first commit of Material-UI
  return (
    <MuiPickersUtilsProvider locale={frLocale} utils={DateFnsUtils}>
      <KeyboardDatePicker
        margin="normal"
        format="dd/MM/yyyy"
        label={label}
        value={value}
        cancelLabel="Annuler"
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": "change time",
        }}
      />
    </MuiPickersUtilsProvider>
  )
}
