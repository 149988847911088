import React from "react"
import { useTheme } from "@material-ui/core/styles"
import { useForm, Controller } from "react-hook-form"
import TextField from "@material-ui/core/TextField"
// import timekit from "timekit-sdk"
import { fetchUserData } from "./AuthListener"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import LoadingBackdrop from "./LoadingBackDrop"
// import SimpleAccordion from "./SimpleAccordion"
// import Resource from "./Resource"
import { useSelector, useDispatch } from "react-redux"
import { selectProject } from "../app/user"
import { Storage } from "aws-amplify"
import useIsMobile from "../utils/useIsMobile"
import config from "../aws-exports"
import ShopAvailablityForm from "./ShopAvailabilityForm"
import SimpleSnack from "./SimpleSnack"
import { restAuth } from "../services/rest"
import FileInputButton from "./FileInputButton"
import HervePaper from "./HervePaper"
import SecondaryButton from "./SecondaryButton"
import DashboardPage from "./DashboardPage"
import { Avatar, Backdrop, Box, Divider, Paper } from "@material-ui/core"
import ImageCrop from "./ImageCrop"
import { Link } from "gatsby"
import { selectCognitoUser } from "../app/cognitoUser"

const urlRegex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/

function dirtyValues(dirtyFields, allValues, root) {
  // If *any* item in an array was modified, the entire array must be submitted, because there's no way to indicate
  // "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (
    dirtyFields === true ||
    Array.isArray(dirtyFields) ||
    (root === false && typeof dirtyFields === "object")
  )
    return allValues
  // Here, we have an object
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      key !== "availability"
        ? dirtyValues(dirtyFields[key], allValues[key], false)
        : allValues[key],
    ])
  )
}

const lowerCaseString = str => str.toLowerCase()

const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",")
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)
  while (n--) u8arr[n] = bstr.charCodeAt(n)
  return new File([u8arr], filename, { type: mime })
}

const slugify = require("slugify")

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config

const cloneDeep = require("clone-deep")

const DynamoValuesToFormValues = data => {
  if (!data) return {}
  const formValues = cloneDeep(data)
  Object.entries(formValues).forEach(([key, value]) => {
    try {
      formValues[key] = JSON.parse(value)
      // eslint-disable-next-line no-empty
    } catch (e) {}
  })
  const { availability, id, meta, name, slug } = formValues
  return { availability, id, meta, name, slug }
}
const formValuesToApiValues = async (formValues, cloudValues) => {
  const apiValues = cloneDeep(formValues)
  if (
    apiValues?.meta?.background_url &&
    typeof apiValues.meta.background_url === "string"
  ) {
    try {
      const file = dataURLtoFile(apiValues.meta.background_url, "test.jpg")
      const extension = file.name.split(".").pop()
      const { type: mimeType } = file
      const key = `images/${apiValues.id}-background.${extension}`
      const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
      await Storage.put(key, file, {
        level: "public",
        contentType: mimeType,
      })
      apiValues.meta.background_url = url
      formValues.meta.background_url = url
    } catch (error) {
      console.warn(error)
    }
  }

  if (
    apiValues?.meta?.logo_url &&
    typeof apiValues.meta.logo_url === "string"
  ) {
    try {
      const file = dataURLtoFile(apiValues.meta.logo_url, "test.jpg")
      const extension = file.name.split(".").pop()
      const { type: mimeType } = file
      const key = `images/${apiValues.id}-logo.${extension}`
      const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
      await Storage.put(key, file, {
        level: "public",
        contentType: mimeType,
      })
      apiValues.meta.logo_url = url
      formValues.meta.logo_url = url
    } catch (error) {
      console.warn(error)
    }
  }
  // if (apiValues.meta.background_url instanceof Blob) {
  //   console.log("we have a blob")
  //   const file = dataURLtoFile(apiValues.meta.background_url, "test.jpg")
  //   return
  // }
  // if (apiValues.meta.logo_url instanceof File) {
  //   const extension = apiValues.meta.logo_url.name.split(".").pop()
  //   const { type: mimeType } = apiValues.meta.logo_url
  //   const key = `${apiValues.id}-logo.${extension}`
  //   const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
  //   try {
  //     await Storage.put(key, apiValues.meta.logo_url, {
  //       level: "public",
  //       contentType: mimeType,
  //     })
  //     apiValues.meta.logo_url = url
  //     formValues.meta.logo_url = url
  //   } catch (error) {
  //     console.warn("file upload failed", error)
  //   }
  // }
  if (
    apiValues?.meta?.catalog_url &&
    apiValues.meta.catalog_url instanceof File
  ) {
    const extension = apiValues.meta.catalog_url.name.split(".").pop()
    const { type: mimeType } = apiValues.meta.catalog_url
    const key = `images/${apiValues.id}-catalog.${extension}`
    const url = `https://${bucket}.s3.${region}.amazonaws.com/public/${key}`
    try {
      await Storage.put(key, apiValues.meta.catalog_url, {
        level: "public",
        contentType: mimeType,
      })
      apiValues.meta.catalog_url = url
      formValues.meta.catalog_url = url
    } catch (error) {
      console.warn("file upload failed", error)
    }
  }
  if (apiValues.availability) {
    ;[
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ].forEach(day => {
      apiValues.availability[day] = apiValues.availability[day]
        ? apiValues.availability[day].map(item => [item[0], item[1]])
        : []
    })
    apiValues.availability = JSON.stringify(apiValues.availability)
  }

  const deleteKeys = ["createdAt", "updatedAt", "userID", "resources"]
  if (!cloudValues.slug && apiValues?.meta?.city && apiValues.name) {
    apiValues.slug = [
      slugify(apiValues.meta.city.toLowerCase()),
      slugify(apiValues.name.toLowerCase()),
    ].join("/")
  } else {
    deleteKeys.push("slug")
  }
  deleteKeys.forEach(key => {
    delete apiValues[key]
  })
  if (apiValues.meta) {
    apiValues.meta = JSON.stringify(apiValues.meta)
  }
  return apiValues
}

const TextInput = props => <TextField variant="outlined" {...props} />

const useStyles = makeStyles(theme => ({
  root: {
    // display: "flex",
    overflow: "scroll",
    // flex: 1,
    // overflow: "hidden",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: 0,
    background: theme.palette.background.main,
    // color: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  title: {
    margin: theme.spacing(2),
  },
  spacedChildren: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    // color: theme.palette.primary.main,
  },
  topButtonContainer: {
    position: "fixed",
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    // justifyContent: "space-between",
    // alignSelf: "stretch",
    marginTop: theme.spacing(2),
    marginBottom: 0,
    padding: theme.spacing(1),
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "white",
    zIndex: 5,
  },
}))
export default function ProjectForm({ id }) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useIsMobile()
  const dispatch = useDispatch()
  const cognitoUser = useSelector(selectCognitoUser)
  // const [inviteEmail, setInviteEmail] = React.useState("")
  const [crop, setCrop] = React.useState({})
  const [formValues, setFormValues] = React.useState({})
  const [cloudValues, setCloudValues] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState()
  const cloudProject = useSelector(selectProject(id))

  const {
    register,
    handleSubmit,
    watch,
    control,
    reset,
    formState: { errors, dirtyFields, isDirty, isSubmitting },
  } = useForm({
    defaultValues: DynamoValuesToFormValues(cloudProject),
    shouldUnregister: true,
    mode: "onBlur",
    reValidateMode: "onChange",
  })

  const onSubmit = ({ dirtyFields, reset }) => async data => {
    setLoading(true)
    const dirty = dirtyValues(dirtyFields, data, true)
    console.log({ dirty, dirtyFields, reset, data })
    const body = await formValuesToApiValues(
      { ...dirty, name: data.name, id },
      cloudValues
    )
    console.log({ body })
    restAuth("post", "/auth/updateProject", body)
      .then(() => {
        fetchUserData(cognitoUser, dispatch)
        setSuccess(true)
      })
      .catch(err => console.warn("project update failed", err))
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    setFormValues(DynamoValuesToFormValues(cloudProject))
    setCloudValues(DynamoValuesToFormValues(cloudProject))
    reset(DynamoValuesToFormValues(cloudProject))
  }, [cloudProject])

  const { slug } = cloudProject

  // console.log({
  //   cloudValues,
  //   lengthValue,
  //   lengthUnit,
  //   formValues,
  //   equal: equal(formValues, cloudValues),
  //   error: !!shopUrl && !urlRegex.test(shopUrl),
  // })
  // console.log("image : " + formValues)
  if (!cloudProject.id) return <LoadingBackdrop open={true} />
  return (
    <DashboardPage title="Magasin" fullScroll>
      {slug ? (
        <Box ml={isMobile ? 1 : 2}>
          <SecondaryButton
            inverted
            component={Link}
            to={`/${slug}`}
            target=" _blank"
            // ml={2}
          >
            Consulter la page client
          </SecondaryButton>
        </Box>
      ) : null}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: 40,
          overflow: "hidden",
        }}
      >
        <form onSubmit={handleSubmit(onSubmit({ dirtyFields, reset }))}>
          <Paper
            className={classes.topButtonContainer}
            style={{
              left: isMobile
                ? theme.breakpoints.up("sm")
                  ? theme.spacing(7) + 1
                  : 0
                : 239,
            }}
          >
            <SecondaryButton
              type="submit"
              disabled={Object.keys(dirtyFields).length === 0}
            >
              Enregistrer les modifications
            </SecondaryButton>
          </Paper>
          <HervePaper title="Informations générales">
            <div
              className={classes.spacedChildren}
              style={{
                display: "flex",
                width: "100%",
                paddingTop: 10,
                flexDirection: "column",
              }}
            >
              <Controller
                name="name"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextInput
                    fullWidth
                    label="Nom du point de vente"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? "Ce champ est requis" : ""}
                  />
                )}
                rules={{ required: "Ce champ est requis" }}
              />
              {/* <TextInput
                name="name"
                fullWidth
                id="name"
                label="Nom du point de vente"
                onChange={event => {
                  const name = event.target.value
                  setFormValues(values => {
                    const newValues = cloneDeep(values)
                    newValues.name = name
                    return newValues
                  })
                }}
              /> */}
              <Controller
                name="meta.address"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextInput
                    fullWidth
                    label="Adresse (numéro, voie)"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="meta.city"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextInput
                    fullWidth
                    label="Ville"
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? "Ce champ est requis" : ""}
                  />
                )}
                rules={{ required: "Ce champ est requis" }}
              />
              <Controller
                name="meta.zip_code"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextInput
                    fullWidth
                    label="Code postal"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="meta.email"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <TextInput
                    fullWidth
                    label="Email du magasin"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : ""}
                  />
                )}
                rules={{
                  pattern: {
                    value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "L'adresse est invalide",
                  },
                }}
              />
              <Controller
                name="meta.phoneNumber"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextInput
                    fullWidth
                    label="Téléphone du magasin"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              <Controller
                name="meta.url"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <TextInput
                    fullWidth
                    label="Site web"
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    error={!!error}
                    helperText={
                      error ? error.message : "Exemple: https://herve.shop"
                    }
                  />
                )}
                rules={{
                  pattern: {
                    value: urlRegex,
                    message: "L'adresse est invalide",
                  },
                }}
              />
              <Controller
                name="meta.shopUrl"
                control={control}
                render={({
                  field: { onChange, onBlur, value },
                  fieldState: { error },
                }) => (
                  <TextInput
                    fullWidth
                    label="Site marchand"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={!!error}
                    helperText={
                      error ? error.message : "Exemple: https://herve.shop"
                    }
                  />
                )}
                rules={{
                  pattern: {
                    value: urlRegex,
                    message: "L'adresse est invalide",
                  },
                }}
              />
              {/* <TextInput
                style={{ marginTop: 20 }}
                value={address}
                id="adress"
                label="Adresse (numéro, voie)"
                onChange={updateMetaProp("address")}
              /> */}
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginRight: isMobile ? 0 : 10,
                  }}
                >
                  <TextInput
                    fullWidth
                    value={city}
                    id="city"
                    label="Ville"
                    onChange={updateMetaProp("city")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginLeft: isMobile ? 0 : 10,
                    marginTop: isMobile ? 20 : 0,
                  }}
                >
                  <TextInput
                    fullWidth
                    value={zip_code}
                    id="zip_code"
                    label="Code postal"
                    onChange={updateMetaProp("zip_code")}
                  />
                </div>
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginRight: isMobile ? 0 : 10,
                  }}
                >
                  <TextInput
                    value={phoneNumber}
                    fullWidth
                    id="phone"
                    label="Téléphone du magasin"
                    onChange={updateMetaProp("phoneNumber")}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginLeft: isMobile ? 0 : 10,
                    marginTop: isMobile ? 20 : 0,
                  }}
                >
                  <TextInput
                    value={email}
                    fullWidth
                    id="email"
                    label="Email du magasin"
                    onChange={updateMetaProp("email")}
                  />
                </div>
              </div> */}
              {/* <div
                style={{
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginRight: isMobile ? 0 : 10,
                  }}
                >
                  <TextInput
                    value={url}
                    fullWidth
                    id="url"
                    label="Site web"
                    onChange={updateMetaProp("url", true)}
                    helperText={
                      !!url && !urlRegex.test(url)
                        ? "Adresse invalide"
                        : "Exemple : https://herve.shop"
                    }
                    error={!!url && !urlRegex.test(url)}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    marginLeft: isMobile ? 0 : 10,
                    marginTop: isMobile ? 20 : 0,
                    marginBottom: 10,
                  }}
                >
                  <TextInput
                    type="url"
                    value={shopUrl}
                    helperText={
                      !!shopUrl && !urlRegex.test(shopUrl)
                        ? "Adresse invalide"
                        : "Exemple : https://rv.herve.shop"
                    }
                    error={!!shopUrl && !urlRegex.test(shopUrl)}
                    fullWidth
                    id="shopUrl"
                    label="Site marchand"
                    onChange={updateMetaProp("shopUrl", true)}
                  />
                </div>
              </div> */}

              {/* <TextInput value={id} id="id" style={{ display: "none" }} /> */}

              {/* <Select
                style={{ display: "none" }}
                variant="standard"
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={mode}
                onChange={event => {
                  const mode = event.target.value
                  setFormValues(values => {
                    const newValues = cloneDeep(values)
                    newValues.availability.mode = mode
                    return newValues
                  })
                }}
              >
                <MenuItem value="roundrobin_random">
                  roundrobin aléatoire
                </MenuItem>
                <MenuItem value="roundrobin_prioritized">
                  roundrobin priorisé
                </MenuItem>
                <MenuItem value="mutual">mutualisation</MenuItem>
              </Select> */}
              <Controller
                name="meta.description"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    rowsMax={10}
                    multiline
                    fullWidth
                    variant="outlined"
                    label="Votre activité en une ligne"
                    value={value}
                    onChange={onChange}
                    inputProps={{ maxLength: 130 }}
                  />
                )}
              />
              {/* <TextField
                rowsMax={5}
                multiline
                fullWidth
                variant="outlined"
                label="Votre activité en une ligne"
                value={description}
                onChange={updateMetaProp("description")}
                inputProps={{ maxLength: 130 }}
              /> */}
              <Controller
                name="meta.fullDescription"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <TextField
                    rowsMax={10}
                    multiline
                    fullWidth
                    variant="outlined"
                    label="Description détaillée"
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
              {/* <TextField
                multiline
                rows={3}
                rowsMax={10}
                fullWidth
                variant="outlined"
                label="Description détaillée"
                value={fullDescription}
                onChange={updateMetaProp("fullDescription")}
              /> */}
              {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <div
                style={{
                  height: "max-content",
                  display: "flex",
                  flex: 1,
                  //overflow: "hidden",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ImageCropper
                  formValues={formValues}
                  setFormValues={setFormValues}
                  image={
                    background_url instanceof File
                      ? URL.createObjectURL(background_url)
                      : background_url
                  }
                />
              </div>
            </div> */}

              {/* <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: 250 }}>
                <FileInputButton
                  title="Sélectionner une image pour le logo (JPG, PNG ou GIF)"
                  accept="image/x-png,image/gif,image/jpeg"
                  onChange={data => {
                    const newValues = cloneDeep(formValues)
                    newValues.meta.logo_url = data
                    setFormValues(newValues)
                  }}
                />
              </div>
              {logo_url && (
                <div
                  style={{
                    height: 100,
                    display: "flex",
                    flex: 1,
                    overflow: "hidden",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={
                      logo_url instanceof File
                        ? URL.createObjectURL(logo_url)
                        : logo_url
                    }
                    style={{
                      height: 100,
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
            </div> */}
              <Controller
                name="meta.catalog_url"
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div
                      style={{
                        maxWidth: isMobile ? 150 : 200,
                        display: "flex",
                      }}
                    >
                      <FileInputButton
                        title="Sélectionner un document pour votre catalogue (PDF)"
                        accept="application/pdf"
                        onChange={data => onChange(data)}
                      />
                    </div>
                    {value ? (
                      <div
                        style={{
                          height: 300,
                          display: "flex",
                          flex: 1,
                          overflow: "hidden",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: 20,
                        }}
                      >
                        <embed
                          src={
                            value instanceof File
                              ? URL.createObjectURL(value)
                              : value
                          }
                          width="100%"
                          height={300}
                          type="application/pdf"
                        />
                      </div>
                    ) : null}
                  </div>
                )}
              />
            </div>
          </HervePaper>

          <HervePaper title="Images">
            <div
              className={classes.spacedChildren}
              style={{
                display: "flex",
                width: "100%",
                paddingTop: 10,
                flexDirection: "column",
              }}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={1}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  style={{ width: isMobile ? 100 : 200 }}
                >
                  <Typography variant="h6">Bannière</Typography>
                  <Box height={20} />
                  <Typography variant="body2">
                    Dimensions recommandées
                  </Typography>
                  <Typography variant="body2">696(L) x 240(H)</Typography>
                </Box>
                <Controller
                  name="meta.background_url"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      {value && (
                        <div
                          style={{
                            height: 100,
                            marginLeft: 10,
                            marginRight: 10,
                            display: "flex",
                            flex: 1,
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={`${value}${
                              value.startsWith("data:")
                                ? ""
                                : `?${new Date().toISOString()}`
                            }`}
                            style={{
                              height: 100,
                              width: "100%",
                              marginLeft: 10,
                              objectFit: "contain",
                            }}
                          />
                        </div>
                      )}
                      <SecondaryButton
                        style={{ width: isMobile ? 100 : 200 }}
                        onClick={() =>
                          setCrop({ key: "background_url", onChange })
                        }
                      >
                        Mettre à jour
                      </SecondaryButton>
                    </>
                  )}
                />
              </Box>
              <Divider />
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                flex={1}
              >
                <Box
                  flexDirection="column"
                  display="flex"
                  style={{ width: isMobile ? 100 : 200 }}
                >
                  <Typography variant="h6">Logo</Typography>
                  <Box height={20} />
                  <Typography variant="body2">
                    Dimensions recommandées
                  </Typography>
                  <Typography variant="body2">200(L) x 200(H)</Typography>
                </Box>
                <Controller
                  name="meta.logo_url"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <>
                      {value && (
                        <div
                          style={{
                            height: 100,
                            // width: 100,
                            marginLeft: 10,
                            marginRight: 10,
                            display: "flex",
                            flex: 1,
                            overflow: "hidden",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            src={`${value}${
                              value.startsWith("data:")
                                ? ""
                                : `?${new Date().toISOString()}`
                            }`}
                            style={{
                              height: 100,
                              width: 100,
                              // objectFit: "contain",
                            }}
                          />
                        </div>
                      )}
                      <SecondaryButton
                        style={{ width: isMobile ? 100 : 200 }}
                        onClick={() => setCrop({ key: "logo_url", onChange })}
                      >
                        Mettre à jour
                      </SecondaryButton>
                    </>
                  )}
                />
              </Box>
            </div>
          </HervePaper>

          <HervePaper title="Horaires d'ouverture du point de vente">
            <ShopAvailablityForm
              // availability={formValues.availability}
              // setFormValues={setFormValues}
              isMobile={isMobile}
              control={control}
            />
          </HervePaper>

          <HervePaper title="Récupération des commandes">
            <Controller
              name="canShip"
              control={control}
              render={({ field: { onChange, value } }) => (
                <>
                  <SecondaryButton
                    style={{ alignSelf: "flex-start" }}
                    onClick={() => onChange(!value)}
                  >
                    {value
                      ? "Désactiver la livraison"
                      : "Autoriser la livraison"}
                  </SecondaryButton>
                  <Typography style={{ marginTop: 20 }}>
                    {value
                      ? "Avec la configuration actuelle, les clients pourront choisir de venir chercher leurs commandes au magasin ou de se faire livrer lors du règlement de leur commande"
                      : "Avec la configuration actuelle, les clients viendront chercher leurs commandes au magasin"}
                  </Typography>
                </>
              )}
            />
          </HervePaper>
        </form>
      </div>
      <LoadingBackdrop open={!formValues.id || loading} />
      <SimpleSnack
        handleClose={() => setSuccess()}
        open={!!success}
        severity="success"
        message="Les modifications ont été prises en compte et seront publiées dans quelques minutes"
      />
      <Backdrop
        className={classes.backdrop}
        open={!!crop.key}
        onClick={() => setCrop({})}
      >
        <Box
          flex={1}
          p={2}
          display="flex"
          flexDirection="column"
          alignSelf="stretch"
          style={{ overflow: "auto" }}
        >
          <HervePaper
            onClick={e => e.stopPropagation()}
            m={2}
            style={{
              flex: 1,
              overflowY: "auto",
            }}
          >
            <ImageCrop
              key={crop.key}
              hideCrop={() => setCrop({})}
              formKey={crop.key}
              onChange={crop.onChange}
              // formValues={formValues}
              // setFormValues={setFormValues}
            />
          </HervePaper>
        </Box>
      </Backdrop>
    </DashboardPage>
  )
}
