import { Translations } from "@aws-amplify/ui-components"

export default {
  [Translations.ADDRESS_LABEL]: "Adresse",
  [Translations.ADDRESS_PLACEHOLDER]: "Saisissez votre adresse",
  [Translations.BACK_TO_SIGN_IN]: "Retour",
  [Translations.BIRTHDATE_LABEL]: "Anniversaire",
  [Translations.BIRTHDATE_PLACEHOLDER]: "Entrez votre anniversaire",
  [Translations.CHANGE_PASSWORD_ACTION]: "Mettre à jour",
  [Translations.CHANGE_PASSWORD]: "Choisissez un nouveau mot de passe",
  [Translations.CODE_LABEL]: "Code de vérification",
  [Translations.CODE_PLACEHOLDER]: "Entrez le code",
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: "Code de confirmation",
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: "Entrez votre code",
  [Translations.CONFIRM_SIGN_UP_FAILED]:
    "Échec de la confirmation de l'inscription",
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: "Confirmer l'inscription",
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: "Vous avez perdu votre code?",
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: "Renvoyer le code",
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: "Confirmer",
  [Translations.CONFIRM_SMS_CODE]: "Confirmer le code SMS",
  [Translations.CONFIRM_TOTP_CODE]: "Confirmer le code TOTP",
  [Translations.CONFIRM]: "Confirmer",
  [Translations.CREATE_ACCOUNT_TEXT]: "Créez votre compte",
  [Translations.EMAIL_LABEL]: "Adresse email *",
  [Translations.EMAIL_PLACEHOLDER]: "E-mail",
  [Translations.FAMILY_NAME_LABEL]: "Nom de famille",
  [Translations.FAMILY_NAME_PLACEHOLDER]: "Entrez votre nom de famille",
  [Translations.FORGOT_PASSWORD_TEXT]: "Mot de passe oublié?",
  [Translations.GENDER_LABEL]: "Sexe",
  [Translations.GENDER_PLACEHOLDER]: "Entrez votre sexe",
  [Translations.GIVEN_NAME_LABEL]: "Prénom",
  [Translations.GIVEN_NAME_PLACEHOLDER]: "Saisissez votre prénom",
  [Translations.IMAGE_PICKER_BUTTON_TEXT]: "Télécharger",
  [Translations.IMAGE_PICKER_HINT]: "Aperçu de l'image avant l'importation",
  [Translations.IMAGE_PICKER_PLACEHOLDER_HINT]:
    "Appuyer pour sélectionner une image",
  [Translations.IMAGE_PICKER_TITLE]: "Ajouter une photo de profil",
  [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]:
    "Moins de deux types de mfa disponibles",
  [Translations.LOCALE_LABEL]: "Locale",
  [Translations.LOCALE_PLACEHOLDER]: "Entrez vos paramètres régionaux",
  [Translations.MIDDLE_NAME_LABEL]: "Second prénom",
  [Translations.MIDDLE_NAME_PLACEHOLDER]: "Entrez votre deuxième prénom",
  [Translations.NAME_LABEL]: "Nom complet",
  [Translations.NAME_PLACEHOLDER]: "Entrez votre nom complet",
  [Translations.NEW_PASSWORD_LABEL]: "Nouveau mot de passe",
  [Translations.NEW_PASSWORD_PLACEHOLDER]: "Entrez votre nouveau mot de passe",
  [Translations.NICKNAME_LABEL]: "Surnom",
  [Translations.NICKNAME_PLACEHOLDER]: "Saisissez votre pseudo",
  [Translations.NO_ACCOUNT_TEXT]: "Pas de compte?",
  [Translations.PASSWORD_LABEL]: "Mot de passe *",
  [Translations.PASSWORD_PLACEHOLDER]: "Mot de passe",
  [Translations.PHONE_LABEL]: "Numéro de téléphone *",
  [Translations.PHONE_PLACEHOLDER]: "(555) 555-1212",
  [Translations.PHOTO_PICKER_BUTTON_TEXT]: "Bouton",
  [Translations.PHOTO_PICKER_HINT]:
    "Le texte ou le contenu auxiliaire peut occuper cet espace ici",
  [Translations.PHOTO_PICKER_PLACEHOLDER_HINT]: "Indice d'espace réservé",
  [Translations.PHOTO_PICKER_TITLE]: "Titre du sélecteur",
  [Translations.PICKER_TEXT]: "Choisissez un fichier",
  [Translations.PICTURE_LABEL]: "URL de l'image",
  [Translations.PICTURE_PLACEHOLDER]: "Saisissez l'URL de votre image",
  [Translations.PREFERRED_USERNAME_LABEL]: "Nom d'utilisateur préféré",
  [Translations.PREFERRED_USERNAME_PLACEHOLDER]:
    "Entrez votre nom d'utilisateur préféré",
  [Translations.PROFILE_LABEL]: "URL du profil",
  [Translations.PROFILE_PLACEHOLDER]: "Saisissez l'URL de votre profil",
  [Translations.QR_CODE_ALT]: "qrcode",
  [Translations.RESET_PASSWORD_TEXT]: "Réinitialiser mon mot de passe",
  [Translations.RESET_YOUR_PASSWORD]: "Réinitialisez votre mot de passe",
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: "Sélectionner le type MFA",
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: "Vérifier",
  [Translations.SEND_CODE]: "Envoyer le code",
  [Translations.SETUP_TOTP_REQUIRED]: "TOTP doit être configuré",
  [Translations.SIGN_IN_ACTION]: "Se connecter",
  [Translations.SIGN_IN_HEADER_TEXT]: "Connectez-vous à votre compte",
  [Translations.SIGN_IN_TEXT]: "Connexion",
  [Translations.SIGN_IN_WITH_AMAZON]: "Connectez-vous avec Amazon",
  [Translations.SIGN_IN_WITH_AUTH0]: "Connectez-vous avec Auth0",
  [Translations.SIGN_IN_WITH_AWS]: "Connectez-vous avec AWS",
  [Translations.SIGN_IN_WITH_FACEBOOK]: "Connectez-vous avec Facebook",
  [Translations.SIGN_IN_WITH_GOOGLE]: "Connectez-vous avec Google",
  [Translations.SIGN_OUT]: "Déconnexion",
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: "E-mail",
  [Translations.SIGN_UP_FAILED]: "Inscription échouée",
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: "Vous avez un compte?",
  [Translations.SIGN_UP_HEADER_TEXT]: "Créer un nouveau compte",
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: "Mot de passe",
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: "Créer un compte",
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: "Nom d'utilisateur",
  [Translations.SUBMIT]: "Soumettre",
  [Translations.SUCCESS_MFA_TYPE]: "Succès! Votre type MFA est maintenant:",
  [Translations.TEXT_FALLBACK_CONTENT]: "Contenu de remplacement",
  [Translations.TOTP_HEADER_TEXT]:
    "Scannez puis entrez le code de vérification",
  [Translations.TOTP_ISSUER]: "AWSCognito",
  [Translations.TOTP_LABEL]: "Entrez le code de sécurité:",
  [Translations.TOTP_SETUP_FAILURE]: "La configuration TOTP a échoué",
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: "Vérifier le jeton de sécurité",
  [Translations.TOTP_SUCCESS_MESSAGE]: "Configuration de TOTP réussie!",
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]:
    "Échec! Impossible de configurer MFA pour le moment",
  [Translations.UPDATED_AT_LABEL]: "Mis à jour à",
  [Translations.UPDATED_AT_PLACEHOLDER]:
    "Saisissez l'heure de la dernière mise à jour des informations",
  [Translations.USERNAME_LABEL]: "Nom d'utilisateur *",
  [Translations.USERNAME_PLACEHOLDER]: "Entrez votre nom d'utilisateur",
  [Translations.VERIFY_CONTACT_EMAIL_LABEL]: "E-mail",
  [Translations.VERIFY_CONTACT_HEADER_TEXT]:
    "La récupération de compte nécessite des coordonnées vérifiées",
  [Translations.VERIFY_CONTACT_PHONE_LABEL]: "Numéro de téléphone",
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: "Soumettre",
  [Translations.VERIFY_CONTACT_VERIFY_LABEL]: "Vérifier",
  [Translations.WEBSITE_LABEL]: "Site Web",
  [Translations.WEBSITE_PLACEHOLDER]: "Entrez votre site Web",
  [Translations.ZONEINFO_LABEL]: "Fuseau horaire",
  [Translations.ZONEINFO_PLACEHOLDER]: "Entrez votre fuseau horaire",
}
