import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import { API, graphqlOperation } from "aws-amplify"
import {
  Button,
  makeStyles,
  Box,
  Typography,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import useIsMobile from "../utils/useIsMobile"
import { useSelector } from "react-redux"
import { selectCognitoUser } from "../app/cognitoUser"
import TableRender from "./TableRender"
import HervePaper from "./HervePaper"

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

const useStyles = isMobile =>
  makeStyles(theme => ({
    table: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      overflowY: "hidden",
      overflowX: "auto",
      margin: theme.spacing(isMobile ? 1 : 2),
      alignSelf: "flex-start",
    },
    nextAppointment: {
      display: "flex",
      //flexDirection: "column",
      flex: 1,
      overflow: "hidden",
      margin: theme.spacing(2),
    },
    nextAppointmentContent: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      textAlign: "center",
    },
  }))

const bookingsByUser = /* GraphQL */ `
  query BookingsByUserByDate(
    $owner: ID
    $start: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingsByUserByDate(
      owner: $owner
      start: $start
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        resourceID
        projectID
        props
        start
        end
        bookType
        status
        recipe
        totalAmount
        deliveryPrice
        canShip
        hasSell
        customerEmail
        customerName
        customerPhone
        sellerEmail
        bookReason
        delivery
        meetUrl
        shipping {
          address {
            city
            country
            line1
            line2
            postal_code
            state
          }
          name
        }
        payment_status
        createdAt
        updatedAt
        project {
          id
          userID
          availability
          published
          createdAt
          meta
          name
          owner
          reminders
          slug
          updatedAt
          canShip
          type
          stripeID
          stripeConnected
        }
        resource {
          id
          owner
          name
          description
          active
          resourceType
          bookDuration
          bufferDuration
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`

const buildFilter = filters => {
  const filter = {}
  filters.forEach(item => {
    // if (item.operator === "=") {
    filter[item.field] = { contains: item.value }
    // }
  })
  console.log("filter is :", filters)
  return filter
}

const bookStatus = {
  confirmed: "Confirmé",
  paid: "Réglé",
  pendingCustomerConfirmation: "Non confirmé",
  "waiting payment": "En attente de règlement",
}

const bookTypes = {
  Visio: "Visioconférence",
  Physical: "au magasin",
  Phone: "téléphonique",
}

const formatRow = item => ({
  ...item,
  bookType: bookTypes[item.bookType],
  status: bookStatus[item.status] || item.status,
  start: `${format(new Date(item.start), "EEEEEE P", {
    locale: fr,
  })} à ${format(new Date(item.start), "p", {
    locale: fr,
  })}`,
})

const LoadMore = ({ loadMore, isLoading, nextToken, buttonRef }) => (
  <Button
    ref={buttonRef}
    variant="contained"
    disabled={isLoading || !nextToken}
    onClick={loadMore}
  >
    Charger plus d'éléments
  </Button>
)

const BookingListTable = () => {
  const isMobile = useIsMobile()
  const classes = useStyles(isMobile)()
  const buttonRef = React.useRef()
  const boxRef = React.useRef()
  const { username: owner } = useSelector(selectCognitoUser)
  const [isLoading, setIsLoading] = useState(false)
  // const [isError, setIsError] = useState(false)
  const [nextToken, setNextToken] = useState(undefined)
  const [value, setValue] = React.useState(0)
  const [showAppointment, setShowAppointment] = React.useState(true)
  const [appointment, setAppointment] = React.useState([])
  const [data, setData] = React.useState([])
  const [filters, setFilters] = React.useState([])
  const [emptyData, setEmptyData] = React.useState(false)

  // const [nextNextToken, setNextNextToken] = useState()
  // const [previousTokens, setPreviousTokens] = useState([])
  // const hasNext = !!nextToken
  // const hasPrev = previousTokens.length

  const fetchData = async ({ reset, token }) => {
    console.log("fetching", { reset, token, filters })
    setIsLoading(true)
    return API.graphql(
      graphqlOperation(bookingsByUser, {
        owner,
        nextToken: token,
        limit: 10,
        sortDirection: "DESC",
        start: { [value ? "lt" : "gt"]: new Date().toISOString() },
        ...(filters.length > 0 ? { filter: buildFilter(filters) } : {}),
      })
    )
      .then(({ data }) => {
        console.log({
          length: data.bookingsByUserByDate.items.length,
          nextToken: data.bookingsByUserByDate.nextToken,
        })
        if (
          data.bookingsByUserByDate.items.length === 0 &&
          data.bookingsByUserByDate.nextToken
        ) {
          setEmptyData(false)
          return fetchData({
            reset,
            token: data.bookingsByUserByDate.nextToken,
          })
        } else {
          const newData = reset
            ? data.bookingsByUserByDate.items.map(formatRow)
            : current => [
                ...current,
                ...data.bookingsByUserByDate.items.map(formatRow),
              ]
          setData(newData)
          setNextToken(data.bookingsByUserByDate.nextToken)
          setIsLoading(false)
          setEmptyData(newData.length === 0)
          return true
        }
      })
      .catch(err => console.warn(err))
  }

  //récupération de la date du prochain rendez-vous

  const fetchDataAppointment = async ({ reset, token }) => {
    console.log("fetching", { reset, token, filters })
    setIsLoading(true)
    return API.graphql(
      graphqlOperation(bookingsByUser, {
        owner,
        nextToken: token,
        limit: 1,
        sortDirection: "DESC",
        start: { gt: new Date().toISOString() },
        ...(filters.length > 0 ? { filter: buildFilter(filters) } : {}),
      })
    )
      .then(({ data }) => {
        if (data.bookingsByUserByDate.items.length === 0) {
          setShowAppointment(false)
        } else {
          setAppointment(
            reset
              ? data.bookingsByUserByDate.items.map(formatRow)
              : current => [
                  ...current,
                  ...data.bookingsByUserByDate.items.map(formatRow),
                ]
          )
          setNextToken(data.bookingsByUserByDate.nextToken)
          setIsLoading(false)
          setShowAppointment(true)
          return true
        }
      })
      .catch(err => console.warn(err))
  }

  React.useEffect(() => {
    fetchData({ reset: true })
    fetchDataAppointment({ reset: true })
  }, [filters, value])

  const loadMore = () => {
    // setPreviousTokens(prev => [...prev, nextToken])
    // setNextToken(nextNextToken)
    // setNextNextToken(null)
    fetchData({ reset: false, token: nextToken }).then(
      setTimeout(() => {
        buttonRef.current.scrollIntoView(true)
      }, 1000)
    )
    // tableRef.current && tableRef.current.onQueryChange()
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  // const prev = () => {
  //   setNextToken(previousTokens.pop())
  //   setPreviousTokens([...previousTokens])
  //   setNextNextToken(null)
  //   tableRef.current && tableRef.current.onQueryChange()
  // }

  // const reset = () => {
  //   setNextToken(undefined)
  //   setPreviousTokens([])
  //   setNextNextToken(null)
  // }

  console.log({
    data,
    nextToken,
    owner,
  })

  return (
    <>
      <HervePaper title="Mon prochain rendez-vous">
        {showAppointment ? (
          <>
            <Typography variant="h6">
              {appointment.map(e => e.status)}
            </Typography>
            <Typography variant="h6">
              {appointment.map(e => e.start)}
            </Typography>
            <Typography variant="h6">
              {appointment.map(e => e.bookType)}
            </Typography>
          </>
        ) : (
          <Typography>Aucun rendez-vous</Typography>
        )}
      </HervePaper>
      <Box mx={2}>
        {/* <AppBar position="static"> */}
        <Tabs
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="à venir" {...a11yProps(1)} disabled={!!isLoading} />
          <Tab label="terminés" {...a11yProps(0)} disabled={!!isLoading} />
        </Tabs>
        {/* </AppBar> */}
      </Box>
      <Box className={classes.table} ref={boxRef}>
        <TableRender
          data={data}
          setFilters={setFilters}
          filters={filters}
          setEmptyData={setEmptyData}
          emptyData={emptyData}
          isLoading={isLoading}
        />
        <div style={{ height: 20 }} />
        <LoadMore
          buttonRef={buttonRef}
          loadMore={loadMore}
          isLoading={isLoading}
          nextToken={nextToken}
        />
      </Box>
    </>
  )
}

export default BookingListTable
