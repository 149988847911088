const { fr } = require("date-fns/locale")
const { utcToZonedTime, format } = require("date-fns-tz")

const timeZone = "Europe/Paris"

const formatOptions = {
  locale: fr,
  timeZone,
}

export const formatParis = (d, tokens) => format(d, tokens, formatOptions)

export const parisDate = d => utcToZonedTime(d, timeZone)
