import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Typography } from "@material-ui/core"
import AdminHint from "./AdminHint"
import useIsMobile from "../utils/useIsMobile"

const useStyles = isMobile =>
  makeStyles(theme => ({
    root: {
      display: "flex",
      // flex: 1,
      overflow: "hidden",
      flexDirection: "column",
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: 0,
      background: theme.palette.background.main,
    },
    scrollRoot: {
      overflowY: "hidden",
      flexDirection: "column",
      flexGrow: 1,
      width: "100%",
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingRight: 0,
      background: theme.palette.background.main,
    },
    title: {
      margin: theme.spacing(isMobile ? 1 : 2),
    },
  }))

export default function DashboardPage({ title, children, fullScroll = true }) {
  const isMobile = useIsMobile()
  const classes = useStyles(isMobile)()
  return (
    <div className={classes[fullScroll ? "scrollRoot" : "root"]}>
      <div style={{ maxWidth: 800 }}>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        <div style={{ display: "flex" }}>
          <AdminHint isMobile={isMobile} />
        </div>

        {children}
      </div>
    </div>
  )
}
