import { Box, Typography } from "@material-ui/core"
import React, { useState, useCallback, useRef, useEffect } from "react"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import SecondaryButton from "./SecondaryButton"

const cloneDeep = require("clone-deep")

export default function ImageCrop({
  image,
  formKey,
  setFormValues,
  formValues,
  hideCrop,
  onChange,
  round,
}) {
  const [upImg, setUpImg] = useState(image)
  const inputRef = React.useRef()
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [crop, setCrop] = useState({ aspect: 348 / 120 })
  const [completedCrop, setCompletedCrop] = useState(null)

  const triggerFileSelectPopup = () => inputRef.current.click()

  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener("load", () => setUpImg(reader.result))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const onLoad = useCallback(image => {
    imgRef.current = image
    if (formKey === "logo_url" || round) {
      if (image.height > image.width) {
        setCrop({ aspect: 1, width: image.width })
      } else {
        setCrop({ aspect: 1, height: image.height })
      }
      return false
    }
    if (image.width / image.height > 348 / 120) {
      setCrop({ aspect: 348 / 120, height: image.height })
    } else {
      setCrop({ aspect: 348 / 120, width: image.width })
    }
    return false
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext("2d")
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio
    canvas.height = crop.height * pixelRatio

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )
  }, [completedCrop])

  const onApplyPress = () => {
    const base64Image = previewCanvasRef.current.toDataURL("image/jpeg")
    let newValues
    if (formValues && formKey && setFormValues) {
      newValues = formValues ? cloneDeep(formValues) : {}
      newValues.meta[formKey] = base64Image
      setFormValues && setFormValues(newValues)
    }
    onChange && onChange(base64Image)
    hideCrop()
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        alignItems: "stretch",
      }}
    >
      <input
        ref={inputRef}
        type="file"
        accept="image/x-png,image/gif,image/jpeg"
        onChange={onSelectFile}
        style={{ display: "none" }}
      />
      <SecondaryButton
        inverted
        onClick={triggerFileSelectPopup}
        style={{
          marginRight: "10px",
          width: "250px",
          height: "fit-content",
          alignSelf: "center",
        }}
      >
        {`Choisir une ${upImg ? "autre" : ""} image (JPG, PNG ou GIF)`}
      </SecondaryButton>
      <Box
        mt={2}
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          alignItems: "stretch",
          borderTop: "1px solid",
          // overflow: "hidden",
        }}
      >
        <Typography>Image originale</Typography>
        <ReactCrop
          src={upImg}
          circularCrop={formKey === "logo_url" || round}
          onImageLoaded={onLoad}
          crop={crop}
          onChange={c => setCrop(c)}
          onComplete={c => setCompletedCrop(c)}
        />
      </Box>

      <Box
        mt={2}
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          borderTop: "1px solid",
        }}
      >
        <Typography>Image finale</Typography>
        <canvas
          ref={previewCanvasRef}
          // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
          style={{
            width: Math.round(completedCrop?.width ?? 0),
            height: Math.round(completedCrop?.height ?? 0),
            borderRadius:
              formKey === "logo_url" || round
                ? Math.round(completedCrop?.height / 2 ?? 0)
                : 0,
          }}
        />
      </Box>
      <Box flexDirection="row" display="flex" mt={2} justifyContent="center">
        <SecondaryButton
          onClick={onApplyPress}
          style={{
            marginRight: "10px",
            width: "250px",
            height: "fit-content",
            alignSelf: "center",
          }}
        >
          Enregistrer
        </SecondaryButton>
        <SecondaryButton
          inverted
          onClick={hideCrop}
          style={{
            marginRight: "10px",
            width: "250px",
            height: "fit-content",
            alignSelf: "center",
          }}
        >
          Annuler
        </SecondaryButton>
      </Box>
    </div>
  )
}
