import React from "react"
import DashboardPage from "./DashboardPage"
import HervePaper from "./HervePaper"
// import { QRCode } from "react-qrcode-logo"
import logo from "../images/WebIcon.png"
import * as EZQRCode from "easyqrcodejs"

export default function Communication() {
  const ezCodeRef = React.useRef()
  React.useEffect(() => {
    var options = {
      logo,
      text: "https://github.com/ushelp/EasyQRCodeJS",
      title: "QR Title", // content
      titleFont: "bold 18px Arial", //font. default is "bold 16px Arial"
      titleColor: "#004284", // color. default is "#000"
      titleBackgroundColor: "#fff", // background color. default is "#fff"
      titleHeight: 70, // height, including subTitle. default is 0
      titleTop: 25, // draws y coordinates. default is 30

      // ====== SubTitle

      subTitle: "QR subTitle", // content
      subTitleFont: "14px Arial", // font. default is "14px Arial"
      subTitleColor: "#004284", // color. default is "4F4F4F"
      subTitleTop: 40, // draws y coordinates. default is 0
    }
    new EZQRCode(ezCodeRef.current, options)
  }, [])
  return (
    <DashboardPage title="Communication">
      <HervePaper title="Codes QR">
        {/* <QRCode
          value="https://github.com/gcoro/react-qrcode-logo"
          logoImage={logo}
        /> */}
        <div ref={ezCodeRef}></div>
      </HervePaper>
    </DashboardPage>
  )
}
