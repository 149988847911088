import React from "react"
import {
  Button,
  Grid,
  Box,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core"
import { Button as GatsbyButton } from "gatsby-theme-material-ui"
import useMediaQuery from "@material-ui/core/useMediaQuery"
// import { format, utcToZonedTime } from "date-fns-tz"
import { format } from "date-fns"
import { fr } from "date-fns/locale"
import CancelIcon from "@material-ui/icons/Cancel"
import UserBookingTabs from "./UserBookingTabs"
import useIsMobile from "../utils/useIsMobile"
import UserBookingTabPanels from "./UserBookingTabPanels"

const boolText = {
  true: "oui",
  false: "non",
}

const useStyles = makeStyles(theme => ({
  title: {
    margin: theme.spacing(2),
  },
  subtitle: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    // color: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
  },
  spaced: { margin: theme.spacing(2) },
  videoRoot: {
    // display: "flex",
    position: "relative",
    // flex: 1,
    overflow: "hidden",
    // flexDirection: "column",
    width: "100%",
    // top: 56,
    // bottom: 0,
    height: "100%",
    background: theme.palette.background.main,
    // background: "red",
    // color: theme.palette.primary.main,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))

export default function AdminBookingContent({
  booking,
  updateBookingItem,
  setLoading,
  setBookingData,
}) {
  const isMobile = useIsMobile()
  const classes = useStyles()
  const {
    id,
    status,
    start,
    end,
    bookType,
    customerName,
    customerGivenName,
  } = booking
  const startDate = new Date(start)
  const endDate = new Date(end)
  const [showVisio, setShowVisio] = React.useState(false)
  const [bookingStatus, setBookingStatus] = React.useState({
    started: Date.now() > startDate,
    ended: Date.now() > endDate,
  })
  const [value, setValue] = React.useState(
    Date.now() > startDate &&
      ["paid", "sent", "delivered", "waiting payment"].includes(status)
      ? 1
      : 0
  )

  React.useEffect(() => {
    setValue(
      Date.now() > startDate &&
        ["paid", "sent", "delivered", "waiting payment"].includes(status)
        ? 1
        : 0
    )
  }, [status])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const slot = `${format(startDate, "p", {
    locale: fr,
  })} - ${format(endDate, "p", { locale: fr })} , le ${format(startDate, "P", {
    locale: fr,
  }).replace(/\//g, ".")} `
  console.log({ booking })

  const { started, ended } = bookingStatus
  if (started && !ended && bookType === "Visio" && showVisio) {
    return (
      <div className={classes.videoRoot}>
        <iframe
          allow="camera;microphone"
          src={`https://main.dsc3qyvy1mibr.amplifyapp.com/ng/${id}`}
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: 0,
            overflow: "hidden",
          }}
        />
        <IconButton onClick={() => setShowVisio(false)}>
          <div
            style={{
              width: 60,
              height: 60,
              background: "white",
              borderRadius: 30,
            }}
          >
            <CancelIcon style={{ width: 60, height: 60 }} />
          </div>
        </IconButton>
      </div>
    )
  }
  return (
    <div
      style={{
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        // overflow: "auto",
        maxWidth: 720,
        width: "100%",
        alignSelf: "center",
      }}
    >
      {/* <UserBookingHeader 
          classes={classes}
          isMobile={isMobile}
          startDate={startDate}
          booking={booking}
          headerBookingPro
      /> */}
      <Box marginBottom={2} width="100%">
        <Typography variant="h4" className={classes.title}>
          {`Rendez-vous du ${format(startDate, "P", {
            locale: fr,
          }).replace(/\//g, ".")} avec ${customerGivenName} ${customerName}`}
        </Typography>
        {/* <Button variant="contained" className={classes.spaced}>
          Modifier le rendez-vous
        </Button>
        <Button variant="contained" className={classes.spaced}>
          Annuler le rendez-vous
        </Button> */}

        {/* <IconButton
          className={classes.spaced}
          onClick={reload}
          style={{ background: "white" }}
        >
          <RefreshIcon />
        </IconButton> */}
      </Box>
      <UserBookingTabs
        isMobile={isMobile}
        value={value}
        handleChange={handleChange}
        startDate={startDate}
        status={status}
        bookingPro
      />
      <UserBookingTabPanels
        value={value}
        isMobile={isMobile}
        ended={ended}
        slot={slot}
        bookingStatus={bookingStatus}
        setBookingStatus={setBookingStatus}
        startDate={startDate}
        setLoading={setLoading}
        classes={classes}
        started={started}
        boolText={boolText}
        updateBookingItem={updateBookingItem}
        bookingPro
        {...booking}
        booking={booking}
        setBookingData={setBookingData}
        // showCallVideo={showCallVideo}
      />
    </div>
  )
}
