import { Typography } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import React from "react"
import HervePaper from "./HervePaper"
import { makeStyles } from "@material-ui/core/styles"
import SecondaryButton from "./SecondaryButton"

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(2),
    alignSelf: "flex-start",
  },
}))

export default function BillingPanelContent({
  title,
  subtitle,
  buttonText,
  feedbacks = [],
  onButtonPress,
  children,
}) {
  const classes = useStyles()
  return (
    <HervePaper title={title} style={{ margin: 0 }}>
      {typeof subtitle === "string" ? (
        <Typography className={classes.marginBottom}>{subtitle}</Typography>
      ) : (
        subtitle
      )}
      {feedbacks.map(feedback => {
        const severity = Object.keys(feedback)[0]
        const str = Object.values(feedback)[0]
        return (
          <Alert key={str} severity={severity} className={classes.marginBottom}>
            {str}
          </Alert>
        )
      })}
      <SecondaryButton
        inverted
        onClick={onButtonPress}
        style={{ alignSelf: "flex-start" }}
      >
        {buttonText}
      </SecondaryButton>
      {children}
    </HervePaper>
  )
}
