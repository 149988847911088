import React, { useState } from "react"
import { Backdrop, Box, makeStyles, TextField } from "@material-ui/core"
import { useSelector } from "react-redux"
import { selectUser } from "../app/user"
import DashboardPage from "./DashboardPage"
import HervePaper from "./HervePaper"
import SecondaryButton from "./SecondaryButton"
import { convertFromHTML, EditorState, ContentState } from "draft-js"
import { convertToHTML } from "draft-convert"
import { Storage } from "aws-amplify"
import config from "../aws-exports"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { restAuth } from "../services/rest"
import LoadingBackdrop from "./LoadingBackDrop"
import Alert from "@material-ui/lab/Alert"
import Loadable from "@loadable/component"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const LoadableEditor = Loadable(() => import("./LoadableEditor"))

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket,
} = config

const titles = {
  cgv: "Conditions générales de vente",
  policy: "Charte de protection des données personnelles",
}

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
}))
export default function Legal() {
  const classes = useStyles()
  const canEdit = useMediaQuery("(min-width:400px)")
  const user = useSelector(selectUser)
  const [loading, setLoading] = React.useState(false)
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  )
  const handleEditorChange = state => setEditorState(state)

  const project = user ? user.projects.items[0] : {}
  const cgv = JSON.parse(project.cgv || "{}")
  const policy = JSON.parse(project.policy || "{}")
  const { id } = project
  const [currentData, setCurrentData] = React.useState({ cgv: {}, policy: {} })
  React.useEffect(() => {
    setCurrentData(state => {
      let newState = { ...state, cgv, policy }
      newState.cgv = { ...newState.cgv, ...JSON.parse(project.cgv || "{}") }
      newState.policy = {
        ...newState.policy,
        ...JSON.parse(project.policy || "{}"),
      }
      return newState
    })
  }, [project])
  const fetchCGV = () =>
    cgv?.url &&
    fetch(cgv.url)
      .then(response => response.text())
      .then(html => {
        console.log({ html })
        setCurrentData(state => {
          let newState = { ...state, cgv, policy }
          newState.cgv.content = html
          return newState
        })
      })
  const fetchPolicy = () =>
    policy?.url &&
    fetch(policy.url)
      .then(response => response.text())
      .then(html => {
        console.log(html)
        setCurrentData(state => {
          let newState = { ...state, cgv, policy }
          newState.policy.content = html
          return newState
        })
      })

  const fetchData = async () => {
    await fetchCGV()
    await fetchPolicy()
  }
  const { key, email } = currentData

  const handleSave = async () => {
    setLoading(true)
    try {
      const html = convertToHTML(editorState.getCurrentContent())
      let url = null
      if (html !== "<p></p>") {
        const fileKey = `html/${id}-${key}.html`
        url = `https://${bucket}.s3.${region}.amazonaws.com/public/${fileKey}`
        await Storage.put(fileKey, html, {
          level: "public",
          contentType: "html",
        })
      }
      await restAuth("post", "/auth/updateProject", {
        id,
        [key]: JSON.stringify({
          email,
          url,
        }),
      })
      // await fetchUserData(cognitoUser, dispatch)
      setCurrentData(state => {
        let newState = { ...state, key: undefined }
        newState[key].content = html === "<p></p>" ? null : html
        newState[key].email = email
        newState[key].url = url
        return newState
      })
    } catch (error) {
      console.warn(error)
    }
    setLoading(false)
  }
  React.useEffect(() => {
    user && fetchData()
  }, [user])
  console.log({ cgv, policy, currentData })
  return (
    <DashboardPage title="Juridique">
      {canEdit ? null : (
        <Box mx={1}>
          <Alert severity="info">
            L'édition de ces documents nécessite un plus grand écran (400 pixels
            de largeur au moins)
          </Alert>
        </Box>
      )}
      <HervePaper title="Conditions générales de vente" height>
        <Box alignItems="flex-start">
          <Box mb={1}>
            <Alert severity={currentData.cgv?.content ? "success" : "info"}>
              {`Vous ${
                currentData.cgv?.content ? "avez" : "n'avez pas encore"
              } renseigné les conditions générales de vente`}
            </Alert>
          </Box>
          {canEdit || currentData.cgv?.content ? (
            <SecondaryButton
              disabled={!canEdit && !currentData.cgv?.content}
              onClick={() => {
                if (!currentData.cgv?.content) {
                  setEditorState(() => EditorState.createEmpty())
                } else {
                  const blocksFromHTML = convertFromHTML(
                    currentData.cgv?.content
                  )
                  console.log({ blocksFromHTML })
                  setEditorState(
                    EditorState.createWithContent(
                      ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap
                      )
                    )
                  )
                }
                setCurrentData(state => ({
                  ...state,
                  key: "cgv",
                  email: currentData.cgv?.email,
                }))
              }}
            >
              {canEdit
                ? currentData.cgv?.url
                  ? "Mettre à jour"
                  : "Renseigner"
                : "Consulter"}
            </SecondaryButton>
          ) : null}
        </Box>
      </HervePaper>
      <HervePaper title="Charte de protection des données personnelles" height>
        <Box alignItems="flex-start">
          <Box mb={1}>
            <Alert severity={currentData.policy?.content ? "success" : "info"}>
              {`Vous ${
                currentData.policy?.content ? "avez" : "n'avez pas encore"
              } renseigné la charte de protection des données personnelles`}
            </Alert>
          </Box>
          {canEdit || currentData.policy?.content ? (
            <SecondaryButton
              disabled={!canEdit && !currentData.policy?.content}
              onClick={() => {
                if (!currentData.policy?.content) {
                  setEditorState(() => EditorState.createEmpty())
                } else {
                  const blocksFromHTML = convertFromHTML(
                    currentData.policy?.content
                  )
                  console.log({ blocksFromHTML })
                  setEditorState(
                    EditorState.createWithContent(
                      ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap
                      )
                    )
                  )
                }
                setCurrentData(state => ({
                  ...state,
                  key: "policy",
                  email: currentData.policy?.email,
                }))
              }}
            >
              {canEdit
                ? currentData.policy?.url
                  ? "Mettre à jour"
                  : "Renseigner"
                : "Consulter"}
            </SecondaryButton>
          ) : null}
        </Box>
      </HervePaper>
      <Backdrop
        open={!!window && !!key}
        className={classes.backdrop}
        onClick={() => setCurrentData(state => ({ ...state, key: undefined }))}
      >
        {window && key ? (
          <HervePaper
            title={titles[key]}
            onClick={e => e.stopPropagation()}
            style={{
              flex: 1,
              overflowY: "hidden",
            }}
          >
            <Box
              flex={1}
              display="flex"
              flexDirection="column"
              alignSelf="stretch"
              style={{ overflow: "hidden" }}
            >
              {canEdit || email ? (
                <Box mb={2}>
                  <TextField
                    variant="outlined"
                    disabled={!canEdit}
                    label="Email de contact"
                    style={{ marginTop: 10 }}
                    value={email}
                    onChange={e =>
                      setCurrentData(state => ({
                        ...state,
                        email: e.target.value,
                      }))
                    }
                  />
                </Box>
              ) : null}
              {canEdit ? (
                <Box flex={1} display="flex" overflow="hidden">
                  <LoadableEditor
                    editorState={editorState}
                    onEditorStateChange={handleEditorChange}
                    // wrapperClassName="wrapper-class"
                    // editorClassName="editor-class"
                    // toolbarClassName="toolbar-class"
                  />
                </Box>
              ) : (
                <div
                  className={classes.htmlContainer}
                  style={{ overflow: "auto" }}
                  dangerouslySetInnerHTML={{
                    __html: currentData[key].content,
                    // __html: testHtml,
                  }}
                />
              )}
              {canEdit ? null : <Box display="flex" flex={1} />}
              <Box display="flex" flexDirection="row" mt={2}>
                {canEdit ? (
                  <Box mr={2}>
                    <SecondaryButton onClick={handleSave}>
                      Enregistrer
                    </SecondaryButton>
                  </Box>
                ) : null}
                <SecondaryButton
                  inverted
                  onClick={() =>
                    setCurrentData(state => ({ ...state, key: undefined }))
                  }
                >
                  {canEdit ? "Annuler" : "Fermer"}
                </SecondaryButton>
              </Box>
            </Box>
          </HervePaper>
        ) : null}
      </Backdrop>
      <LoadingBackdrop open={loading} />
    </DashboardPage>
  )
}
