import { Box, Grid, Paper, Toolbar, Typography } from "@material-ui/core"
import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { selectCognitoUser } from "../app/cognitoUser"
import { useSelector } from "react-redux"
import { StaticImage } from "gatsby-plugin-image"
import { selectUser } from "../app/user"

const useStyles = makeStyles(theme => ({
  box: {
    display: "flex",
    flex: 1,
    backgroundColor: theme.palette.background.main,
    flexDirection: "column",
    padding: theme.spacing(2),
    overflow: "hidden",
    position: "absolute",
    height: "100vh",
    width: "100%",
    alignItems: "center",
  },
  paper: {
    position: "relative",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 720,
    // padding: theme.spacing(1),
    // flexWrap: "wrap",

    overflow: "hidden",
  },
}))

const ImagePaper = ({ children }) => (
  <Box display="flex" flex={1} mx={1} my={2}>
    <Paper
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        overflow: "hidden",
      }}
      elevation={5}
    >
      <Box display="flex" flex={1} m={1} overflow="hidden">
        {children}
      </Box>
    </Paper>
  </Box>
)

const Image = ({ src }) => (
  <img
    atl="Prise de rendez-vous"
    style={{
      objectFit: "contain",
      overflow: "hidden",
      display: "flex",
      flex: 1,
      width: "100%",
    }}
    src={src}
  />
)

const Image2 = () => (
  <StaticImage
    atl="Appel vidéo"
    objectFit="contain"
    imgStyle={{
      objectFit: "contain",
      overflow: "hidden",
      display: "flex",
      flex: 1,
    }}
    src="../images/Appel-Video.png"
  />
)

const Image3 = () => (
  <StaticImage
    alt="Envoi du reçu"
    objectFit="contain"
    imgStyle={{
      objectFit: "contain",
      overflow: "hidden",
      display: "flex",
      flex: 1,
    }}
    src="../images/Envoi-du-recu.png"
  />
)

const Image4 = () => (
  <StaticImage
    alt="Choix du mode de retrait"
    objectFit="contain"
    imgStyle={{
      objectFit: "contain",
      overflow: "hidden",
      display: "flex",
      flex: 1,
    }}
    src="../images/Click-and-collect.png"
  />
)

export default function GuestPage() {
  const classes = useStyles()
  const cognitoUser = useSelector(selectCognitoUser)
  console.log({ cognitoUser })
  return (
    <Box className={classes.box}>
      <Toolbar />
      <Paper className={classes.paper}>
        <Box mx={1}>
          <Typography variant="h3" align="center">
            Bienvenue sur HERVE.SHOP
          </Typography>
          <Typography variant="body1">
            {`Merci de consulter votre boîte de réception (${cognitoUser.email}) pour poursuivre l'inscription.`}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "hidden",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              overflow: "hidden",
            }}
          >
            <ImagePaper>
              <Image src={"/Prise-de-rendez-vous.png"} />
            </ImagePaper>
            <ImagePaper>
              <Image src={"/Appel-Video.png"} />
            </ImagePaper>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              flex: 1,
              overflow: "hidden",
            }}
          >
            <ImagePaper>
              <Image src={"/Envoi-du-recu.png"} />
            </ImagePaper>
            <ImagePaper>
              <Image src={"/Click-and-collect.png"} />
            </ImagePaper>
            {/* <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <StaticImage
                imgStyle={{
                  objectFit: "contain",
                  overflow: "hidden",
                  display: "flex",
                  flex: 1,
                }}
              />
            </Box>
            <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <StaticImage
                imgStyle={{
                  objectFit: "contain",
                  overflow: "hidden",
                  display: "flex",
                  flex: 1,
                }}
                src="../images/Click-and-collect.png"
              />
            </Box> */}
          </Box>
        </Box>
        {/* <Grid
          container
          alignContent="center"
          alignItems="center"
          style={{ display: "flex", flex: 1, margin: 20 }}
        >
          <GridItem />
          <GridItem />
          <GridItem />
          <GridItem />
        </Grid> */}
      </Paper>
    </Box>
  )
}
