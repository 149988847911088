import React from "react"
import Button from "@material-ui/core/Button"
import useIsMobile from "../utils/useIsMobile"
// import defaultResource from "../constants/defaultResource";
import LoadingBackdrop from "./LoadingBackDrop"
import { parisDate, formatParis } from "../utils/date"
// import { useHistory } from "react-router-dom";
import {
  Avatar,
  Backdrop,
  Box,
  Grid,
  makeStyles,
  Paper,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core"
import { useSelector } from "react-redux"
import { selectUser } from "../app/user"
import { IconButton } from "@material-ui/core"
import SimpleSnack from "./SimpleSnack"
import DateRangeIcon from "@material-ui/icons/DateRange"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import CancelIcon from "@material-ui/icons/Cancel"
import { restAuth } from "../services/rest"
import HervePaper from "./HervePaper"
import { navigate } from "gatsby-link"
import DashboardPage from "./DashboardPage"
import { fetchUserData } from "./AuthListener"
import { selectCognitoUser } from "../app/cognitoUser"
import { useDispatch } from "react-redux"
import Alert from "@material-ui/lab/Alert"
import SecondaryButton from "./SecondaryButton"

import { useForm, Controller } from "react-hook-form"
import { validate } from "@material-ui/pickers"
import PrimaryButton from "./PrimaryButton"
import { Link } from "gatsby"
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const merge = require("deepmerge")

const TextInput = props => <TextField fullWidth variant="outlined" {...props} />

const useStyles = makeStyles(theme => ({
  userResourcesRoot: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: 0,
    background: theme.palette.background.main,
    // color: theme.palette.primary.main,
  },
  title: {
    margin: theme.spacing(2),
  },
  invertedButton: {
    color: "white",
    background: theme.palette.secondary.main,
  },
  paper: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    // color: theme.palette.primary.main,
  },
  syncIcon: {
    marginBottom: 0,
    paddingBottom: 0,
    backgroundColor: "white",
    borderRadius: "50%",
  },
  listHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    paddingBottom: theme.spacing(2),
  },
  calendarBadgeContainer: {
    position: "absolute",
    width: 18,
    height: 18,
    bottom: 0,
    right: 0,
    justifyContent: "center",
    alignItems: "center",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}))

const EmployeeList = ({ title, employees, icon, classes, isMobile }) => {
  if (!Array.isArray(employees) || !employees.length > 0) return null
  return (
    <Box flexDirection="column" mt={1}>
      <Box display="flex" flexDirection="row" alignItems="center">
        {icon}
        <Typography variant="overline">{title}</Typography>
      </Box>
      {employees.map(employee => (
        <Employee
          key={employee.id}
          data={employee}
          classes={classes}
          isMobile
        />
      ))}
    </Box>
  )
}

const Employee = ({ data, classes, owned, isMobile }) => {
  console.log("Employee data", data)
  if (!data) return null
  const {
    family_name = "Nom",
    given_name = "Prénom",
    calendarAccess,
    shopAdmin,
    image,
    picture,
  } = data
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        alignItems: "center",
        marginTop: 10,
        // border: "1px solid",
      }}
    >
      <Avatar style={{ width: 50, height: 50 }} src={image || picture} />
      <Typography
        style={{ display: "flex", flex: 1, margin: 10 }}
      >{`${given_name} ${family_name}${
        shopAdmin ? " (vous)" : ""
      }`}</Typography>
      {/* {!owned ? (
        <div
          style={{
            position: "relative",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 10,
          }}
        >
          <DateRangeIcon color="primary" style={{ fontSize: 36 }} />
          <div
            className={classes.calendarBadgeContainer}
            style={{ color: calendarAccess ? "green" : "red" }}
          >
            {calendarAccess ? (
              <CheckCircleIcon className={classes.syncIcon} />
            ) : (
              <CancelIcon className={classes.syncIcon} />
            )}
          </div>
        </div>
      ) : null} */}
      {owned ? (
        <Box mt={isMobile ? 2 : 0}>
          {calendarAccess ? (
            <Alert
              component={Button}
              onClick={() => navigate(`/dashboard/calendar`)}
              style={{ alignItems: "center", paddingLeft: 6 }}
              icon={
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <DateRangeIcon color="primary" style={{ fontSize: 36 }} />
                  <div
                    className={classes.calendarBadgeContainer}
                    style={{ color: calendarAccess ? "green" : "red" }}
                  >
                    {calendarAccess ? (
                      <CheckCircleIcon className={classes.syncIcon} />
                    ) : (
                      <CancelIcon className={classes.syncIcon} />
                    )}
                  </div>
                </div>
              }
              severity="success"
            >
              Calendrier connecté !
            </Alert>
          ) : (
            <SecondaryButton
              inverted
              onClick={() => navigate(`/dashboard/calendar`)}
              style={{ paddingLeft: 10, paddingRight: 10, marginTop: 10 }}
            >
              Synchroniser mon calendrier
            </SecondaryButton>
          )}
        </Box>
      ) : null}
      {!owned ? (
        <IconButton
          onClick={() =>
            navigate(`/dashboard/calendar${shopAdmin ? "" : `/${data.id}`}`)
          }
        >
          <MoreHorizIcon />
        </IconButton>
      ) : null}
      {owned && <Divider variant="fullWidth" />}
    </div>
  )
}
let renderCount = 0
export default function UserResources() {
  const { handleSubmit, control, watch } = useForm()
  renderCount++
  const classes = useStyles()
  const dispatch = useDispatch()
  const isMobile = useIsMobile()
  const cognitoUser = useSelector(selectCognitoUser)
  const user = useSelector(selectUser)
  const [formValues, setFormValues] = React.useState({})
  const [employees, setEmployees] = React.useState([])
  const [invite, setInvite] = React.useState()
  const [error, setError] = React.useState()
  const [project, setProject] = React.useState()
  React.useEffect(() => {
    if (user) {
      setProject(user.projects.items[0])
      let e = [...user.ownedUsers.items]
      e.sort((a, b) => {
        if (a.id === user.id) return -1
        if (b.id === user.id) return 1
        return Number(!!b.calendarAccess) - Number(!!a.calendarAccess)
        // return 0
      })
      e[0] = { ...e[0], shopAdmin: true }
      setEmployees(e)
    }
  }, [user])
  const [openBackdrop, setOpenBackdrop] = React.useState(false)
  const updateForm = propName => event => {
    const prop = event.target.value
    setFormValues(values =>
      merge(values, {
        [propName]: prop,
      })
    )
  }
  const { given_name, family_name, email } = formValues
  const handleInvite = () => {
    setError()
    setOpenBackdrop(true)
    restAuth("post", "/auth/createInvite", {
      email: email.toLowerCase(),
      projectID: project.id,
      given_name,
      family_name,
    })
      .then(response => {
        if (response.error) throw response.error
        setFormValues({})
      })
      .then(() => fetchUserData(cognitoUser, dispatch))
      // .then(response => dispatch(addUserResource(response.data.getResource)))
      .catch(err => {
        setError(err)
        console.warn(err)
      })
      .finally(() => setOpenBackdrop(false))
  }
  const remainingSeats =
    project?.payedSeats - employees?.filter(u => u.calendarAccess).length

  const cancelInvite = () => {
    setError()
    setOpenBackdrop(true)
    restAuth("post", "/auth/deleteInvite", {
      id: invite.id,
    })
      .then(response => {
        if (response.error) throw response.error
        setInvite()
      })
      .then(() => fetchUserData(cognitoUser, dispatch))
      // .then(response => dispatch(addUserResource(response.data.getResource)))
      .catch(err => {
        setError(err)
        console.warn(err)
      })
      .finally(() => setOpenBackdrop(false))
  }
  console.log({ sentInvites: user?.sentInvites })

  const onSubmit = async data => {
    console.log("data:", data)
  }

  const valueWatch = watch("email")
  console.log(valueWatch)
  const syncedEmployees = employees.filter(employee => employee.calendarAccess)
  const notSyncedEmployees = employees.filter(
    employee => !employee.shopAdmin && !employee.calendarAccess
  )
  console.log({ employees })
  return (
    <DashboardPage title="Employés">
      <div style={{ overflowY: "auto" }}>
        <HervePaper title="Invitez vos employés">
          <Typography variant="h6">
            Renseignez les informations pour inviter vos employés.
          </Typography>
          <Typography>
            Vos vendeurs recevront un email d'invitation pour rejoindre votre
            magasin
          </Typography>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              marginTop: 20,
            }}
          >
            <TextInput
              label="Nom"
              value={family_name || ""}
              onChange={updateForm("family_name")}
            />
            <div style={{ width: 10, height: isMobile ? 20 : 0 }} />

            <TextInput
              label="Prénom"
              value={given_name || ""}
              onChange={updateForm("given_name")}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              marginTop: 20,
              alignItems: "center",
            }}
          >
            <TextInput
              label="Email"
              value={email || ""}
              onChange={updateForm("email")}
              style={{ marginBottom: isMobile ? 20 : 0 }}
              helperText={
                email && !emailRegex.test(email)
                  ? "L'adresse email n'est pas valide"
                  : ""
              }
            />
            <Button
              className={classes.invertedButton}
              variant="contained"
              type="submit"
              disabled={
                !email || !given_name || !family_name || !emailRegex.test(email)
              }
              onClick={handleInvite}
            >
              Envoyer l'invitation
            </Button>
          </div>
        </HervePaper>
        {user?.sentInvites.items.length > 0 ? (
          <HervePaper title="Invitations envoyées">
            {user?.sentInvites.items.map(invite => (
              <Box
                key={invite.id}
                display="flex"
                width="100%"
                flexDirection="row"
                overflow="hidden"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography key={invite.id} noWrap display="inline">
                  {invite.toEmail}
                </Typography>
                <IconButton onClick={() => setInvite(invite)}>
                  <MoreHorizIcon />
                </IconButton>
              </Box>
            ))}
          </HervePaper>
        ) : null}
        <HervePaper title="Liste des employés">
          <Alert severity="info">
            <Typography>
              {remainingSeats > 0 ? (
                <Typography>{`Votre plan actuel vous permet de connecter encore ${remainingSeats} calendrier(s)`}</Typography>
              ) : (
                <Typography>{`Toutes les connexions de calendriers incluses dans votre plan sont utilisées. Pour connecter d'autres calendriers, mettez à jour votre plan.`}</Typography>
              )}
            </Typography>
          </Alert>
          {remainingSeats === 0 ? (
            <Box my={2} display="flex" flexDirection="column">
              <PrimaryButton component={Link} to="/dashboard/billing" inverted>
                Mettre à jour mon plan
              </PrimaryButton>
            </Box>
          ) : null}
          {employees && employees.length > 0 && !employees[0].calendarAccess ? (
            <Employee
              classes={classes}
              owned
              data={{ ...employees[0], calendarAccess: false }}
            />
          ) : null}
          <EmployeeList
            isMobile={isMobile}
            title="Calendriers synchronisés"
            icon={
              <div
                style={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <DateRangeIcon color="primary" style={{ fontSize: 30 }} />
                <div
                  className={classes.calendarBadgeContainer}
                  style={{ color: "green" }}
                >
                  <CheckCircleIcon
                    className={classes.syncIcon}
                    style={{ fontSize: 18 }}
                  />
                </div>
              </div>
            }
            employees={syncedEmployees}
            classes={classes}
          />
          <EmployeeList
            isMobile={isMobile}
            title="Calendriers non synchronisés"
            icon={
              <div
                style={{
                  position: "relative",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: 10,
                }}
              >
                <DateRangeIcon color="primary" style={{ fontSize: 30 }} />
                <div
                  className={classes.calendarBadgeContainer}
                  style={{ color: "red" }}
                >
                  <CancelIcon
                    className={classes.syncIcon}
                    style={{ fontSize: 18 }}
                  />
                </div>
              </div>
            }
            employees={notSyncedEmployees}
            classes={classes}
          />
          {/* {employees.map(employee => (
            <Employee
              key={employee.id}
              data={employee}
              classes={classes}
              owned={employee.id === user.id}
            />
          ))} */}
        </HervePaper>
      </div>
      {invite && !openBackdrop ? (
        <Backdrop className={classes.backdrop} open={true}>
          <Box mx={2}>
            <Paper>
              <Box p={2}>
                <Typography>
                  {`Vous avez invité ${
                    invite.toEmail
                  } à rejoindre votre magasin le ${formatParis(
                    parisDate(invite.createdAt),
                    "PPPP"
                  )}.\nVotre invitation n'a pas encore reçu de réponse.`}
                </Typography>
                <Box mt={2} />
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Button variant="contained" onClick={cancelInvite}>
                      Annuler l'invitation
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button variant="contained" onClick={() => setInvite()}>
                      Conserver l'invitation
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Box>
        </Backdrop>
      ) : null}
      <LoadingBackdrop open={openBackdrop} />
      <SimpleSnack
        handleClose={() => setError()}
        open={!!error}
        severity="error"
      />
    </DashboardPage>
  )
}
