import AdminDashboard from "../components/AdminDashboard"
import DashboardIcon from "@material-ui/icons/Dashboard"
import StoreIcon from "@material-ui/icons/Store"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import PeopleIcon from "@material-ui/icons/People"
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted"
import EventIcon from "@material-ui/icons/Event"
import PaymentIcon from "@material-ui/icons/Payment"
import DateRangeIcon from "@material-ui/icons/DateRange"
import GavelIcon from "@material-ui/icons/Gavel"
import UserResources from "../components/UserResources"
import ProjectForm from "../components/ProjectForm"
import EmployeeAccount from "../components/EmployeeAccount"
import Attributions from "../components/Attributions"
import BookingList from "../components/BookingList"
import Billing from "../components/Billing"
import EmployeeCalendar from "../components/EmployeeCalendar"
import Legal from "../components/Legal"
import EmployeeDashboard from "../components/EmployeeDashboard"

export default {
  ShopsAdmin: [
    {
      title: "Tableau de bord",
      path: "",
      Component: AdminDashboard,
      Icon: DashboardIcon,
    },
    { divider: true },
    {
      title: "Magasin",
      path: "project",
      Icon: StoreIcon,
      Component: ProjectForm,
    },
    {
      title: "Employés",
      path: "resources",
      Icon: PeopleIcon,
      Component: UserResources,
    },
    {
      title: "Services",
      path: "attributions",
      Icon: FormatListBulletedIcon,
      Component: Attributions,
    },
    { divider: true },
    {
      title: "Plan",
      path: "billing",
      Icon: PaymentIcon,
      Component: Billing,
    },
    {
      title: "Juridique",
      path: "legal",
      Icon: GavelIcon,
      Component: Legal,
    },
    { divider: true },
    {
      title: "Profil",
      path: "account",
      Icon: AccountCircleIcon,
      Component: EmployeeAccount,
    },
    {
      title: "Calendrier",
      path: "calendar",
      Icon: DateRangeIcon,
      Component: EmployeeCalendar,
    },
    {
      title: "Rendez-vous",
      path: "bookings",
      Icon: EventIcon,
      Component: BookingList,
    },
  ],
  ShopEmployee: [
    {
      title: "Tableau de bord",
      path: "",
      Component: EmployeeDashboard,
      Icon: DashboardIcon,
    },
    { divider: true },
    {
      title: "Profil",
      path: "account",
      Component: EmployeeAccount,
      Icon: AccountCircleIcon,
    },
    {
      title: "Calendrier",
      path: "calendar",
      Component: EmployeeCalendar,
      Icon: DateRangeIcon,
    },
    // { label: "Paramètres", path: "settings", Icon: SettingsIcon },
    {
      title: "Rendez-vous",
      path: "bookings",
      Component: BookingList,
      Icon: EventIcon,
    },
  ],
}
