import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import { Avatar, CircularProgress, Paper, Typography } from "@material-ui/core"
import { selectUser } from "../app/user"
import { useSelector } from "react-redux"
import DateRangeIcon from "@material-ui/icons/DateRange"
import { navigate } from "gatsby"
import AdminHint from "./AdminHint"
import { restAuth } from "../services/rest"

const cloneDeep = require("clone-deep")
var equal = require("deep-equal")

const DynamoValuesToFormValues = data => {
  if (!data) return {}
  const formValues = cloneDeep(data)
  Object.entries(formValues).forEach(([key, value]) => {
    try {
      formValues[key] = JSON.parse(value)
    } catch (e) {
      if (typeof value === "object") {
        try {
          formValues[key] = DynamoValuesToFormValues(value)
        } catch (e) {}
      }
    }
  })
  return formValues
}

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  spacedChildren: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
  pageRoot: {
    display: "flex",
    flex: 1,
    overflow: "hidden",
    flexDirection: "column",
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: 0,
    background: theme.palette.background.main,
    // color: theme.palette.primary.main,
  },
  pageTitle: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
}))
export default function UserForm({ id }) {
  const classes = useStyles()
  const [cloudValues, setCloudValues] = React.useState({})
  const [formValues, setFormValues] = React.useState({})
  const cloudResource = useSelector(selectUser)
  React.useEffect(() => {
    restAuth("get", "/auth/getCalendars").then(response => {
      console.log({ calendars: response })
      // if (response.success) {
      //   setCalendars({
      //     loading: false,
      //     error: false,
      //     data: response.body,
      //   })
      // }
    })
  }, [id])
  React.useEffect(() => {
    setFormValues(DynamoValuesToFormValues(cloudResource))
    setCloudValues(DynamoValuesToFormValues(cloudResource))
  }, [cloudResource])
  // const updateResource = () => {
  //   const body = formValuesToApiValues(formValues)
  //   console.log({ body })
  //   setLoading(true)
  //   restAuth("post", "/auth/updateResource", body)
  //     .then(() => dispatch(updateUserResource(formValues)))
  //     .catch(err => console.warn("project update failed", err))
  //     .finally(() => setLoading(false))
  // }
  // const addAvailability = () =>
  //   setFormValues(values => {
  //     const newValues = cloneDeep(values)
  //     if (!newValues.availability_constraints)
  //       newValues.availability_constraints = []
  //     newValues.availability_constraints.push({
  //       allow_day_and_time: {
  //         day: "Monday",
  //         start: "10:00",
  //         end: "18:00",
  //       },
  //     })
  //     return newValues
  //   })
  // const onClick = () => navigate("/dashboard/calendars")
  const {
    // first_name = "",
    // last_name = "",
    // email = "",
    // image = "",
    // availability_constraints = [],
    calendarAccess,
    // selectedCalendars = [],
  } = formValues
  const syncedCalendar =
    Object.keys(formValues).length === 0 ? undefined : !!calendarAccess
  console.log({
    syncedCalendar,
    cloudValues,
    formValues,
    equal: equal(formValues, cloudValues),
  })
  return (
    <div className={classes.pageRoot}>
      <Typography variant="h3" className={classes.pageTitle}>
        Tableau de bord
      </Typography>
      <AdminHint />
      {/* <EmployeeDashboardCalendar selectedCalendars={selectedCalendars} /> */}
      {syncedCalendar !== false ? (
        <Paper
          style={{
            alignSelf: "flex-start",
            margin: 10,
            padding: 10,
            display: "flex",
            flexDirection: "column",
          }}
        >
          {syncedCalendar === undefined && <CircularProgress />}
          {/* {syncedCalendar === false && (<AdminHint />)} */}
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography>
              Pour commencer, connectez votre calendrier et paramétrez vos
              rendez-vous
            </Typography>
            <div style={{ width: 10 }} />
            <Button variant="contained" onClick={onClick}>
              C'est parti !
            </Button>
          </div> */}
          {/* )} */}
          {syncedCalendar && (
            <>
              <Typography variant="h6">Calendrier synchronisé</Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Avatar>
                  <DateRangeIcon />
                </Avatar>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: 10,
                    flex: 1,
                  }}
                >
                  <Typography>GOOGLE</Typography>
                  {/* <Typography>{kloudless?.account?.account}</Typography> */}
                </div>
                <Button
                  variant="contained"
                  onClick={() => navigate("/dashboard/calendar")}
                >
                  Modifier
                </Button>
              </div>
            </>
          )}
        </Paper>
      ) : null}
    </div>
  )
}
